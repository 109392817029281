import React, { Component } from "react";
import autoBind from "react-autobind";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";

import strings from "../../services/strings";
import language from "../../services/language";

import _ from "lodash";
import "./DocumentOrderField.scss";
import GlossaryForm from "../glossary/GlossaryForm";
import Switch from "../Switch.js";
import ReactAvatarEditor from "react-avatar-editor";

import ActiveLabel from "../ActiveLabel";
import * as documentsActions from "../../store/documents/actions";
import * as documentsSelectors from "../../store/documents/selectors";
import Editor from "../Editor";
// import Mention from "react-tinymce-mention";

import Dropzone from "react-dropzone";

import {
  SortableHandle,
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";

import "./DocumentForm.scss";
import Helpbox from "./Helpbox";
import WorkFlowCodes from "./WorkFlowCodes";
import { browserHistory } from "../../helpers";
import CustomDropModal from "../common/Popup";

let modal, templateData;

const SortableDragger = SortableHandle(() => {
  return <i className="label-drag ion-android-more-vertical" />;
});

const SortableItem = SortableElement(
  ({ value, handleFieldSize, fieldsSize }) => {
    let fieldName = value.name;
    if (value.placeholderField && value.placeholderField !== "") {
      fieldName = value.placeholderField;
    }
    if (
      value.placeholderField === "" &&
      value.placeholder &&
      value.placeholder !== ""
    ) {
      fieldName = value.placeholder;
    }
    let fieldWidth =
      fieldsSize[value.name] !== undefined ? fieldsSize[value.name] : 100;
    return (
      <div
        style={{
          padding: "2px",
          flex: `0 0 ${parseInt(fieldWidth)
            ? parseInt(fieldWidth) > 20
              ? parseInt(fieldWidth)
              : 20
            : 20
            }%`,
          zIndex: "1100",
          minWidth: "0px",
        }}
      >
        <div
          className={
            value.fromCodeBlock
              ? "ActiveLabel activeFields fromCodeBlock"
              : "ActiveLabel activeFields"
          }
        >
          <div className="label-content fieldsContent">
            {!value.fromCodeBlock ? <SortableDragger /> : false}
            <span />
            {fieldName}
          </div>
          <div className="fieldSizeContent">
            {!value.fromCodeBlock ? (
              <input
                type="number"
                key={value.name}
                className="fieldSize"
                value={fieldWidth}
                onChange={(e) => {
                  handleFieldSize(e.target.value, value);
                }}
              />
            ) : (
                <span>{fieldWidth}</span>
              )}
          </div>
        </div>
      </div>
    );
  }
);

const SortableList = SortableContainer(
  ({ items, handleFieldSize, fieldsSize }) => {
    let newItems = [];
    _.map(items, (value, ind) => {
      newItems.push(_.extend({ newIndex: ind }, value));
    });
    let rows = _.map(newItems, (value) => {
      return (
        <SortableItem
          key={`item-${value.newIndex}`}
          index={value.newIndex}
          value={value}
          handleFieldSize={handleFieldSize}
          fieldsSize={fieldsSize}
        />
      );
    });
    return <div className="fieldWrap">{rows}</div>;
  }
);

class StepTabs extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      orderedItem: null,
    };
  }

  componentDidMount() {
    if (this.props.items) {
      this.setState({
        orderedItem: this.props.items,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      orderedItem: nextProps.items,
    });
  }

  // copy of StepList.js , Extra return  => code: Item.name,
  buildLabel(Items, item) {
    let data = [];
    let { expandGlossaries, glossariesCodeIdToName } = this.props;
    // let _glossaries = this.props.glossaries;
    Items.forEach(function (Item) {
      let _label = Item.name;
      // separate out all selectors and loop through
      let result = strings.getFromBetween.get(_label, "[[", "]]");
      // result.forEach(e => {
      //   const reg = new RegExp(e);
      //   // if there's a translation - use it
      //   const _value = localStorage[e + "_translation"] || localStorage[e];
      //   if (_value !== "" && !!_value) {
      //     _label = _label.replace(reg, _value).replace(/\[\[|\]\]/g, "");
      //   } else {
      //     // work out pattern [[*.*_*]] for selects
      //     if (e.indexOf(".") > -1) {
      //       const field = e.split(".")[0];
      //       const values = e.split(".")[1].split("_");
      //       const selected = localStorage[field];
      //       if (selected) {
      //         const _keys = ["field", "selector", "if"];
      //         _keys.forEach(e => {
      //           if (this.paths[e] && this.paths[e][field]) {
      //             const path = this.paths[e][field];
      //             const rule = path[Object.keys(path)[0]].node;

      //             let i = 0;
      //             for (var item in rule.options) {
      //               if (item === selected) break;
      //               i++;
      //             }
      //             _label = _label
      //               .replace(reg, values[i])
      //               .replace(/\[\[|\]\]/g, "");
      //           }
      //         });
      //       }
      //     } else {
      //       // if there's no placeholder there, grab all glossaries
      //       // then concat everything and loop through to find what we're looking for
      //       let _value = _.map(_glossaries, element => {
      //         return JSON.parse(element.value);
      //       });

      //       if (_value !== []) {
      //         let _merged = [];
      //         _value.forEach(element => {
      //           _merged = _merged.concat(
      //             element["fields"],
      //             element["clauses"],
      //             element["selectors"]
      //           );
      //         });
      //         let replacement = _.find(_merged, element => {
      //           return element.name === e;
      //         });
      //         if (replacement) {
      //           _label = _label
      //             .replace(reg, replacement.placeholderField)
      //             .replace(/\[\[|\]\]/g, "");
      //         }
      //       }
      //     }
      //   }
      // });
      result.forEach((e) => {
        const reg = new RegExp(e);

        //if multiple codes are responsible for name of a step
        let stepNameOptions = e.split("||");
        if (stepNameOptions.length > 1) {
          stepNameOptions.forEach((element) => {
            element = element.trim();
            // work out pattern [[*.*_*]] for selects
            if (element.indexOf(".") > -1) {
              const field = element.split(".")[0];
              if (expandGlossaries && _.size(expandGlossaries)) {
                if (
                  expandGlossaries[field] &&
                  expandGlossaries[field].placeholderField
                ) {
                  _label = _label
                    .replace(element, expandGlossaries[field].placeholderField)
                    .replace(/\[\[|\]\]/g, "");
                } else if (
                  expandGlossaries[glossariesCodeIdToName[field]] &&
                  expandGlossaries[glossariesCodeIdToName[field]]
                    .placeholderField
                ) {
                  _label = _label
                    .replace(
                      element,
                      expandGlossaries[glossariesCodeIdToName[field]]
                        .placeholderField
                    )
                    .replace(/\[\[|\]\]/g, "");
                } else {
                  _label = _label
                    .replace(element, "< ## >")
                    .replace(/\[\[|\]\]/g, "");
                }
              }
            } else {
              if (expandGlossaries && _.size(expandGlossaries)) {
                if (
                  expandGlossaries[element] &&
                  expandGlossaries[element].placeholderField
                ) {
                  _label = _label
                    .replace(
                      element,
                      expandGlossaries[element].placeholderField
                    )
                    .replace(/\[\[|\]\]/g, "");
                } else if (
                  expandGlossaries[glossariesCodeIdToName[element]] &&
                  expandGlossaries[glossariesCodeIdToName[element]]
                    .placeholderField
                ) {
                  _label = _label
                    .replace(
                      element,
                      expandGlossaries[glossariesCodeIdToName[element]]
                        .placeholderField
                    )
                    .replace(/\[\[|\]\]/g, "");
                } else {
                  _label = _label
                    .replace(element, "< ## >")
                    .replace(/\[\[|\]\]/g, "");
                }
              }
            }
          });
        }
        //if single code is responsible for name of a step
        // work out pattern [[*.*_*]] for selects
        else if (e.indexOf(".") > -1) {
          const field = e.split(".")[0];
          if (expandGlossaries && _.size(expandGlossaries)) {
            if (
              expandGlossaries[field] &&
              expandGlossaries[field].placeholderField
            ) {
              _label = _label
                .replace(reg, expandGlossaries[field].placeholderField)
                .replace(/\[\[|\]\]/g, "");
            } else if (
              expandGlossaries[glossariesCodeIdToName[field]] &&
              expandGlossaries[glossariesCodeIdToName[field]].placeholderField
            ) {
              _label = _label
                .replace(
                  reg,
                  expandGlossaries[glossariesCodeIdToName[field]]
                    .placeholderField
                )
                .replace(/\[\[|\]\]/g, "");
            } else {
              _label = _label.replace(reg, "< ## >").replace(/\[\[|\]\]/g, "");
            }
          }
        } else {
          // if there's no placeholder there, then find placeholder in all glossaries
          if (expandGlossaries && _.size(expandGlossaries)) {
            if (expandGlossaries[e] && expandGlossaries[e].placeholderField) {
              _label = _label
                .replace(reg, expandGlossaries[e].placeholderField)
                .replace(/\[\[|\]\]/g, "");
            } else if (
              expandGlossaries[glossariesCodeIdToName[e]] &&
              expandGlossaries[glossariesCodeIdToName[e]].placeholderField
            ) {
              _label = _label
                .replace(
                  reg,
                  expandGlossaries[glossariesCodeIdToName[e]].placeholderField
                )
                .replace(/\[\[|\]\]/g, "");
            } else {
              _label = _label.replace(reg, "< ## >").replace(/\[\[|\]\]/g, "");
            }
          }
        }
      });
      data.push({
        id: Item.id,
        code: Item.name,
        name: _label,
      });
    });
    return data;
  }

  handleStepSort = (preNextIndex, pointerObject) => {
    this.props.onStepOrderChange(preNextIndex, pointerObject);
  };

  render() {
    let { orderedItem } = this.state;
    if (!orderedItem) {
      return null;
    }

    let codeName = this.buildLabel(orderedItem);

    const SortableStepList = SortableContainer(({ items }) => {
      let newItems = [];
      _.map(items, (value, ind) => {
        newItems.push(_.extend({ newIndex: ind }, value));
      });
      let rows = _.map(newItems, (value) => {
        return (
          <SortableStepItem
            key={`item-${value.newIndex}`}
            index={value.newIndex}
            value={value}
          />
        );
      });
      return <div style={{ width: "100%" }}>{rows}</div>;
    });

    const SortableStepItem = SortableElement(({ value }) => {
      let placeholder = _.find(codeName, ["code", value.name]);
      let className =
        placeholder.id === this.props.currentItemId
          ? "tabItem step-label-content active"
          : "tabItem step-label-content";
      return (
        <div className="ActiveLabel">
          <div
            className={className}
            onClick={() => {
              this.props.onClick(placeholder.id);
            }}
          >
            <SortableDragger />
            <span />
            {placeholder.name}
          </div>
        </div>
      );
    });

    return (
      <SortableStepList
        items={orderedItem}
        onSortEnd={(oldIndex, newIndex) => {
          this.handleStepSort(oldIndex, newIndex);
        }}
        axis="xy"
        useDragHandle
        lockToContainerEdges
        helperClass=""
      />
    );
  }
}

class DocumentForm extends Component {
  state = {
    currentItemLoaded: false,
    stepsLoaded: false,
    fieldsLoaded: false,
    file: null,
    fileRejected: false,
    payed: false,
    checkedReadMore: false,
    form: {
      category_id: "",
      language_id: language.get(),
      name: "",
      description: "",
      read_more_link: "",
      template: "",
      payed: 0,
      price: "",
      published: 0,
      shareable: false,
      meta_title: "",
      meta_description: "",
      meta_keyword: "",
      version_type: "minor",
      documents: {},
      marked_codes: [],
      label_preference: "default",
    },
    currentRule: {},
    image: null,
    scale: 0.2,
    currItem: null,
    fieldsSize: {},
    fieldsPercentageStatus: {
      minPer: 20,
      error: false,
    },
    selectedItem:{},
    expandGlossaries: {},
    glossariesCodeIdToName: {},
    allDoc: [],
    editToolTip: false,
    codeSuggest: [],
    fieldSuggest: []
  };
  setEditorRef = (editor) => (this.editor = editor);

  // handleChange(e) {
  //   this.setState({ [e.target.name]: e.target.value });
  // }

  removeImage = (image, file) => () => {
    this.setState({
      image: null,
      file: null,
    });
  };

  cropImage = () => {
    // let crooperD = this.editor.getImageScaledToCanvas().toDataURL();
    // console.log('this.editor.getImageScaledToCanvas()')
    // let form = _.extend(this.state.form, {});
    // form.image = form.image.asMutable
    //   ? form.image.asMutable()
    //   : form.image
    // form.image.preview = crooperD;
    this.setState({
      image: null,
      file: this.editor.getImageScaledToCanvas().toDataURL(),
      currItem: null,
    });
  };
  componentDidMount() {
    modal = this.refs.orderFieldsModal;
    this.tryLoadCurrentItem();
    this.loadGlossaries();
    this.getAllDocuments();
  }

  componentDidUpdate() {
    this.tryLoadCurrentItem();
    if (
      this.refs.orderFieldsModal &&
      this.refs.orderFieldsModal.state &&
      this.refs.orderFieldsModal.state.hidden === true &&
      this.state.fieldsPercentageStatus.error === true
    ) {
      let fieldsPercentageStatus = this.state.fieldsPercentageStatus;
      fieldsPercentageStatus.error = false;
      this.setState({
        fieldsPercentageStatus,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.stepsOrder) {
      this.handleStepsOrderUpdate(nextProps);
    }
    if (
      nextProps.currentItem &&
      typeof this.state.form.documents !==
      typeof nextProps.currentItem.related_documents
    ) {
      this.loadRelatedDocuments();
    }
    if (
      nextProps.glossaries &&
      nextProps.glossaries !== this.props.glossaries &&
      !_.size(this.state.expandGlossaries)
    ) {
      this.loadGlossaries(nextProps);
    }
    const {selectedItem} = this.state
    if(selectedItem.codeName) {
      this.handlePopup(selectedItem.codeName)
    }
  }

  constructor(props) {
    super(props);
    autoBind(this);
  }

  loadRelatedDocuments = () => {
    let { form, allDoc } = this.state;
    if (typeof form.documents === "string") {
      form.documents = JSON.parse(form.documents);
    }
    if (allDoc) {
      Object.keys(allDoc).map((value, index) => {
        if (
          this.props.currentItem &&
          this.props.currentItem.related_documents &&
          this.props.currentItem.related_documents.length &&
          this.props.currentItem.related_documents.includes(
            `${allDoc[index].id}`
          )
        ) {
          form.documents[allDoc[index].id] = allDoc[index];
        }
      });
      if (
        Object.keys(this.state.form.documents).length >=
        Object.keys(allDoc).length ||
        Object.keys(this.state.form.documents).length >=
        Object.keys(allDoc).length - 1
      ) {
        form.documents = {};
      }
    }
    this.setState({ form });
    if(this.props.currentItem && this.state.codeSuggest.length==0 && this.state.fieldSuggest.length==0){
      this.getSuggesions(this.props.currentItem.category_id);
    }
  };

  loadGlossaries = (props = this.props) => {
    if (
      props.glossaries &&
      _.size(props.glossaries) &&
      !_.size(this.state.expandGlossaries)
    ) {
      let temp = {};
      let tempCodeIdToName = {};
      let _glossaries = props.glossaries;
      _.map(_glossaries, (element) => {
        if (element.clauses && element.clauses.length) {
          element.clauses.forEach((clause) => {
            temp[`${clause.name}`] = clause;
            tempCodeIdToName[`clause_${clause.id}`] = `${clause.name}`;
          });
        }
        if (element.fields && element.fields.length) {
          element.fields.forEach((field) => {
            temp[`${field.name}`] = field;
            tempCodeIdToName[`field_${field.id}`] = `${field.name}`;
          });
        }
        if (element.selectors && element.selectors.length) {
          element.selectors.forEach((selector) => {
            temp[`${selector.name}`] = selector;
            tempCodeIdToName[`selector_${selector.id}`] = `${selector.name}`;
          });
        }    
      });

      this.setState({
        expandGlossaries: temp,
        glossariesCodeIdToName: tempCodeIdToName,
      });
    }
  };

  getSuggesions = (category_id) =>{
    let codeSuggest = [];
    let fieldSuggest = [];
    let itemElements = this.props.itemElements;

    _.map(itemElements,(elements,key) => {
      let name = "";
      const  filterElements = _.filter(elements,(codes)=> codes.glossary_id == category_id);
      const fElements = _.uniqBy(filterElements, function (e) {
        return e.id;
      });

      if(key==="itemKeyCarousels"){
         name=`carousel.carousel_`
      }
      else if(key==="itemKeyClauses"){
          name=`if.clause_`
      }
      else if(key==="itemKeySelectors"){
        name=`select.select_`
      }
      else if(key==="itemKeyCodeBlocks"){
        name=`code.block_`
      }
      else {
        name = "field_"
      }

      _.forEach(fElements,(code) => {
          if(key==="itemKeyFields") {
            fieldSuggest.push({name: `${name}${code.id}`, contentName: code.name});
          }
          else {
            codeSuggest.push({name: `${name}${code.id}`, contentName: code.name});
          }
      });
    });
    this.setState({
      codeSuggest,
      fieldSuggest
    });
  }

  getAllDocuments = () => {
    strings.getAllDocuments(strings.language.iso2 || strings.language).then(
      (allDoc) => {
        this.setState({ allDoc }, () => {
          this.loadRelatedDocuments();
        });
      },
      (error) => {
        console.log("error", error);
      }
    );
  };

  tryLoadCurrentItem() {
    if (this.props.currentItem && !this.state.currentItemLoaded) {
      let form = _.extend({}, this.state.form);
      let payed;
      _.map(this.state.form, (value, key) => {
        form[key] = this.props.currentItem[key]
          ? this.props.currentItem[key]
          : this.state.form[key];
        if (key === "payed") payed = !!form[key];
        if (key === "marked_codes") {
          try {
            if (this.props.currentItem[key]) {
              form[key] = JSON.parse(this.props.currentItem[key]);
              if (typeof form[key] === "string") form[key] = [];
            }
          } catch (error) {
            form[key] = [];
          }
        }
      });
      this.setState(
        {
          currentItemLoaded: true,
          payed,
          form,
          currItem: this.props.currentItem,
        },
        () => {
          this.getAllDocuments();
        }
      );
    }
    if (
      ((_.size(this.props.fields) ||
        _.size(this.props.selectors) ||
        _.size(this.props.clauses)) &&
        !this.state.fieldsLoaded) ||
      (this.state.fieldsLoaded &&
        (_.size(this.props.fields) !== this.state.fieldsCount ||
          _.size(this.props.selectors) !== this.state.selectorsCount ||
          _.size(this.props.clauses) !== this.state.clausesCount ||
          _.size(this.props.carousels) !== this.state.carouselsCount))
    ) {
      let newFields = _.map(this.props.fields, (item) => {
        return item.asMutable ? item.asMutable() : item;
      });
      _.map(this.props.selectors, (item) => {
        newFields.push(item.asMutable ? item.asMutable() : item);
      });
      _.map(this.props.clauses, (item) => {
        newFields.push(item.asMutable ? item.asMutable() : item);
      });
      _.map(this.props.carousels, (item) => {
        newFields.push(item.asMutable ? item.asMutable() : item);
      });
      let codeBlocksCode = {};
      _.map(newFields, (item) => {
        if (item.fromCodeBlock) {
          if (!codeBlocksCode[item.codeBlockId]) {
            let x = {};
            x[item.name] = item;
            let e = {};
            e[item.stepId] = x;
            codeBlocksCode[item.codeBlockId] = x;
          } else {
            codeBlocksCode[item.codeBlockId][item.name] = item;
          }
        }
      });
      if (this.props.fieldsOrder) {
        let { fieldsSize } = this.state;
        _.map(newFields, (item) => {
          if (typeof this.props.fieldsOrder[item.name] !== "undefined") {
            item.index = this.props.fieldsOrder[item.name].order;
            fieldsSize[item.name] = this.props.fieldsOrder[item.name].size;
          }
        });
        newFields = _.sortBy(newFields, "index");
        this.setState({ fieldsSize }, () => {
          // this.handleCodesSizeAccordingCodeBlock();
        });
      }
      newFields = this.orderFieldsByStep(newFields);
      this.setState(
        {
          fieldsLoaded: true,
          fields: newFields,
          formChanged: false,
          fieldsCount: _.size(this.props.fields),
          // If need, then use the below counts in above if condition
          selectorsCount: _.size(this.props.selectors),
          clausesCount: _.size(this.props.clauses),
          carouselsCount: _.size(this.props.carousels),
        },
        () => {
          // this.arrangeCodesAccordingCodeBlock(codeBlocksCode);
        }
      );
    }

    if (
      _.size(this.props.stepsFromValidation) === 0 &&
      this.state.stepsLoaded
    ) {
      this.setState({
        stepsLoaded: false,
      });
    }
    if (_.size(this.props.stepsFromValidation) && !this.state.stepsLoaded) {
      let items = _.map(this.props.stepsFromValidation, (step) => {
        return step;
      });
      let orderedItem = _.orderBy(items, ["order"], ["asc"]);
      let current_step_id = 0;
      if (orderedItem[0].id) {
        current_step_id = orderedItem[0].id;
      }
      this.setState(
        {
          stepsLoaded: true,
          steps: items,
          currentStepId: current_step_id,
        },
        () => {
          this.handleStepsOrderUpdate(this.props);
        }
      );
    }
    templateData = this.state.form;
  }

  // arrangeCodesAccordingCodeBlock = (codeBlocksCode = {}) => {
  //   let { fields } = this.state;
  //   let tempArray = [];
  //   if (this.props.codeBlocks && _.size(this.props.codeBlocks)) {
  //     for (const iterator of this.props.codeBlocks) {
  //       if (iterator.contentCodeOrder) {
  //         try {
  //           let contentCodeOrder = JSON.parse(iterator.contentCodeOrder);
  //           let orderKeys = Object.keys(contentCodeOrder);
  //           orderKeys.forEach(element => {
  //             tempArray.push(codeBlocksCode[iterator.id][element]);
  //           });
  //         } catch (error) {
  //           console.trace(error);
  //         }
  //       }
  //     }
  //   }
  //   codeBlocksCode = {};
  //   _.map(tempArray, item => {
  //     if (!codeBlocksCode[item.codeBlockId]) {
  //       let x = [];
  //       x.push(item);
  //       let e = {};
  //       e[item.stepId] = x;
  //       codeBlocksCode[item.codeBlockId] = e;
  //     } else {
  //       if (!codeBlocksCode[item.codeBlockId][item.stepId]) {
  //         let x = [];
  //         x.push(item);
  //         codeBlocksCode[item.codeBlockId][item.stepId] = x;
  //       } else {
  //         codeBlocksCode[item.codeBlockId][item.stepId].push(item);
  //       }
  //     }
  //   });
  //   //find positions of orderd codeblocks codes
  //   let codeBlockCodeNames = {};
  //   _.map(fields, steps => {
  //     codeBlockCodeNames[steps[0].stepId] = Object.values(steps);
  //   });
  //   let codesPosition = {};
  //   _.map(codeBlocksCode, codeBlock => {
  //     _.map(codeBlock, steps => {
  //       _.map(steps, code => {
  //         if (!codesPosition[code.codeBlockId]) {
  //           let x = [];
  //           x.push(codeBlockCodeNames[code.stepId].indexOf(code));
  //           let e = {};
  //           e[code.stepId] = x;
  //           codesPosition[code.codeBlockId] = e;
  //         } else {
  //           if (!codesPosition[code.codeBlockId][code.stepId]) {
  //             let x = [];
  //             x.push(codeBlockCodeNames[code.stepId].indexOf(code));
  //             codesPosition[code.codeBlockId][code.stepId] = x;
  //           } else {
  //             codesPosition[code.codeBlockId][code.stepId].push(
  //               codeBlockCodeNames[code.stepId].indexOf(code)
  //             );
  //             codesPosition[code.codeBlockId][code.stepId] = codesPosition[
  //               code.codeBlockId
  //             ][code.stepId].sort();
  //           }
  //         }
  //       });
  //     });
  //   });
  //   let duplicate = fields;
  //   let finalCodes = {};
  //   _.map(duplicate, steps => {
  //     _.map(steps, codes => {
  //       _.map(codesPosition, codesBlock => {
  //         if (codesBlock[codes.stepId]) {
  //           if (!codesBlock[codes.stepId].includes(steps.indexOf(codes))) {
  //             if (finalCodes[duplicate.indexOf(steps)]) {
  //               finalCodes[duplicate.indexOf(steps)].push(codes);
  //             } else {
  //               let  = [];
  //               h.push(codes);
  //               finalCodes[duplicate.indexOf(steps)] = h;
  //             }
  //           } else {
  //             if (codesBlock[codes.stepId][0] === steps.indexOf(codes)) {
  //               _.map(codeBlocksCode, codeBlock => {
  //                 _.map(codeBlock, codeSteps => {
  //                   if (codeSteps[0].stepId === steps[0].stepId) {
  //                     if (finalCodes[duplicate.indexOf(steps)]) {
  //                       finalCodes[duplicate.indexOf(steps)] = [
  //                         ...finalCodes[duplicate.indexOf(steps)],
  //                         ...codeSteps
  //                       ];
  //                     } else {
  //                       finalCodes[duplicate.indexOf(steps)] = [...codeSteps];
  //                     }
  //                   }
  //                 });
  //               });
  //             }
  //           }
  //         } else {
  //           finalCodes[duplicate.indexOf(steps)] = steps;
  //         }
  //       });
  //     });
  //   });
  //   if (!_.size(codesPosition)) {
  //     finalCodes = fields;
  //   }
  //   this.setState({ fields: finalCodes }, () => {
  //     // this.handleCodesSizeAccordingCodeBlock();
  //   });
  // };

  // handleCodesSizeAccordingCodeBlock = () => {
  //   let { fields, selectors, clauses, codeBlocks } = this.props;
  //   let { fieldsSize } = this.state;
  //   let allFields = {};
  //   _.map(fields, item => {
  //     allFields[item.name] = item.asMutable ? item.asMutable() : item;
  //   });
  //   _.map(selectors, item => {
  //     allFields[item.name] = item.asMutable ? item.asMutable() : item;
  //   });
  //   _.map(clauses, item => {
  //     allFields[item.name] = item.asMutable ? item.asMutable() : item;
  //   });

  //   _.map(codeBlocks, codeBlock => {
  //     let contentCodeOrder = {};
  //     if (codeBlock.contentCodeOrder && codeBlock.contentCodeOrder !== "") {
  //       contentCodeOrder = JSON.parse(codeBlock.contentCodeOrder);
  //       _.map(allFields, field => {
  //         if (field.fromCodeBlock && field.codeBlockId === codeBlock.id) {
  //           fieldsSize[field.name] = contentCodeOrder[field.name].size;
  //         }
  //       });
  //     }
  //   });
  //   this.setState({ fieldsSize });
  // };

  handleStepsOrderUpdate = (props) => {
    let { steps } = this.state;
    let stepsOrder = props.stepsOrder ? props.stepsOrder : null;
    if (stepsOrder && stepsOrder.length) {
      if (typeof stepsOrder === "string") stepsOrder = JSON.parse(stepsOrder);
      steps = _.sortBy(steps, function (value, key) {
        return stepsOrder.indexOf(value.name);
      });
    } else {
      steps = _.orderBy(steps, ["order"], ["asc"]);
    }
    this.setState({
      steps,
    });
  };

  hasError(inputName) {
    return inputName === "image"
      ? this.state.fileRejected
      : !!this.props.exceptions[inputName];
  }

  getErrorClass(inputName, defaultClasses = "") {
    return this.hasError(inputName)
      ? defaultClasses + " has-error"
      : defaultClasses;
  }

  getErrorMessage(inputName) {
    if (
      inputName === "template" &&
      _.isObject(this.props.exceptions[inputName])
    ) {
      return strings.get("Exceptions.notAddedRules");
    } else {
      return this.props.exceptions[inputName];
    }
  }

  // getPreviewImage() {
  //   if (this.state.file) {
  //     return <img src={this.state.file.preview} />;
  //   } else {
  //     return this.props.currentItem && this.props.currentItem.imageURL ? (
  //       <img src={this.props.currentItem.imageURL} />
  //     ) : null;
  //   }
  // }

  handleAddRule(e, ruleName, type) {
    e.preventDefault();
    this.setState({ currentRule: { name: ruleName, type } });
    this.showRuleAddModal();
  }

  showRuleAddModal() {
    this.refs.ruleAddModal.show();
  }

  hideRuleAddModal() {
    this.refs.ruleAddModal.hide();
  }

  getTemplateErrors() {
    if (
      this.props.exceptions.template &&
      this.props.exceptions.template.notDefined
    ) {
      let errors = this.props.exceptions.template.notDefined;

      let wrongFields = "";
      let wrongClauses = "";
      let wrongSelectors = "";
      if (errors) {
        if (errors.fields) {
          wrongFields = _.map(_.uniq(errors.fields), (field, i) => {
            return (
              <span key={`item-${i}`}>
                {field}
                <button
                  onClick={(e) => {
                    this.handleAddRule(e, field, "field");
                  }}
                  className="btn btn-default add-button"
                  id={i}
                >
                  +
                </button>
                <br />
              </span>
            );
          });
        }
        if (errors.clauses) {
          wrongClauses = _.map(_.uniq(errors.clauses), (clause, i) => {
            return (
              <span key={`item-${i}`}>
                {clause}
                <button
                  onClick={(e) => {
                    this.handleAddRule(e, clause, "clause");
                  }}
                  className="btn btn-default add-button"
                  id={i}
                >
                  +
                </button>
                <br />
              </span>
            );
          });
        }
        if (errors.selectors) {
          wrongSelectors = _.map(_.uniq(errors.selectors), (selector, i) => {
            return (
              <span key={`item-${i}`}>
                {selector}
                <button
                  onClick={(e) => {
                    this.handleAddRule(e, selector, "selector");
                  }}
                  className="btn btn-default add-button"
                  id={i}
                >
                  +
                </button>
                <br />
              </span>
            );
          });
        }
      }

      return (
        <div className="alert alert-danger">
          {/* {errors.selectors ? <p>Selectors: {wrongSelectors}</p> : null}
          {errors.clauses ? <p>Clauses: {wrongClauses}</p> : null}
          {errors.fields ? <p>Fields: {wrongFields}</p> : null} */}
          {errors.selectors ? (
            <p>
              {strings.get("App.glossaries.glossaryForm.selectors.title")}:{" "}
              {wrongSelectors}
            </p>
          ) : null}
          {errors.clauses ? (
            <p>
              {strings.get("App.glossaries.glossaryForm.clauses.title")}:{" "}
              {wrongClauses}
            </p>
          ) : null}
          {errors.fields ? (
            <p>
              {strings.get("App.glossaries.glossaryForm.fields.title")}:{" "}
              {wrongFields}
            </p>
          ) : null}
        </div>
      );
    } else if (
      this.props.exceptions.template &&
      this.props.exceptions.template.rulesTree
    ) {
      return (
        <div className="alert alert-danger">
          <p>
            {strings.get("App.documents.documentForm.invalidTemplateMessage")}
          </p>
        </div>
      );
    } else if (
      this.props.exceptions.template &&
      this.props.exceptions.template.codeMismatch
    ) {
      return (
        <div className="alert alert-danger">
          <p>
            {this.props.exceptions.template.type === "open" ?
              `${this.props.exceptions.template.codeMismatch} ${strings.get("Exceptions.open_tag_error")}` :
              (
                this.props.exceptions.template.type === "close" ?
                  `${this.props.exceptions.template.codeMismatch} ${strings.get("Exceptions.close_tag_error")}` :
                  `${strings.get("Exceptions.unknown_tag_error")} ${this.props.exceptions.template.codeMismatch}`
              )
            }
          </p>
        </div>
      );
    }
  }

  handleInputChange(e) {
    let form = {};

    form[e.target.name] = e.target.value;
    this.setState({
      form: _.extend(this.state.form, form),
    });
  }

  // handleEditorChange(e) {
  //   this.setState({
  //     tmpItem: _.extend(this.state.form, {
  //       description: e.target.getContent(),
  //     }),
  //   });
  // }

  handleEditorChange(content) {
    this.setState({
      tmpItem: _.extend(this.state.form, {
        description: content,
      }),
    });
  }

  handlePayedChange(e) {
    this.setState({
      payed: e,
      form: _.extend(this.state.form, {
        payed: e ? 1 : 0,
      }),
    });
  }

  handleChangeToRegex(contentTemplate, itemKey, isRegex) {
    let tempRegex = null;
    while (null !== (tempRegex = isRegex.exec(contentTemplate))) {
      if (tempRegex) {
        if (
          itemKey[tempRegex[1]] &&
          itemKey[tempRegex[1]] &&
          itemKey[tempRegex[1]].id
        ) {
          if (
            isRegex.toString() === new RegExp(/.*\[select_(.*)\]/g).toString()
          ) {
            contentTemplate = contentTemplate.replace(
              `_${tempRegex[1]}`,
              `.select_${itemKey[tempRegex[1]].id}`
            );
          } else {
            contentTemplate = contentTemplate.replace(
              `_${tempRegex[1]}`,
              `_${itemKey[tempRegex[1]].id}`
            );
          }
        }
      }
    }
    return contentTemplate;
  }

  handlePopup = (id) =>{
    const {itemElements} = this.props;
    let match
      _.map(itemElements,(item)=>{
        if(_.has(item,id)){
          match = item[id];
        }
      });
      if(match){
      this.setState({
        selectedItem:{
          codeName: id,
          ...match,
        }
      })
      this.refs.tooltipModal.show();
    }
  }

  handleTemplateChange(e) {
    let contentTemplate = e; //.target.getContent();
    let {
      itemKeySelectors,
      itemKeyClauses,
      itemKeyFields,
      itemKeyCodeBlocks,
      itemKeyCarousels,
    } = this.props.itemElements;

    let isSelector = new RegExp(/.*\[select_(.*)\]/g);
    let isClause = new RegExp(/.*\[if.clause_(.*)\]/g);
    let isField = new RegExp(/.*\[\[field_(.*)\]\]/g);
    let iscodeBlock = new RegExp(/.*\[code.block_(.*)\]/g);
    let isCarousel = new RegExp(/.*\[carousel_(.*)\]/g);

    contentTemplate = this.handleChangeToRegex(
      contentTemplate,
      itemKeySelectors,
      isSelector
    );
    contentTemplate = this.handleChangeToRegex(
      contentTemplate,
      itemKeyFields,
      isField
    );
    contentTemplate = this.handleChangeToRegex(
      contentTemplate,
      itemKeyClauses,
      isClause
    );
    contentTemplate = this.handleChangeToRegex(
      contentTemplate,
      itemKeyCodeBlocks,
      iscodeBlock
    );
    contentTemplate = this.handleChangeToRegex(
      contentTemplate,
      itemKeyCarousels,
      isCarousel
    );

    this.setState({
      form: _.extend(this.state.form, {
        template: contentTemplate,
      }),
    });
    templateData = this.state.form;
  }

  setEditorRef = (editor: any) => {
    if (editor) {
      this.editor = editor;
      // console.log('this.editor', this.editor)
      // const img = this.editor.getImageScaledToCanvas().toDataURL();
      // console.log('hello i am here', img)
    }
  };

  getPreviewImage(file) {
    if (this.state.file) {
      return <img src={this.state.file} alt="preview" />;
    } else {
      return this.state.currItem && this.state.currItem.imageURL ? (
        <img src={this.state.currItem.imageURL} alt="preview" />
      ) : null;
    }
  }

  isImage(file) {
    if (this.state.currItem && this.state.currItem.imageURL) {
      return true;
    } else {
      return false;
    }
  }

  setImage = (fileName, formKey) => () => {
    this.setState({
      [fileName]: null,
      currItem: {
        imageURL: null,
      },
    });
    // if(this.props.currentItem && this.props.currentItem.imageURL) {
    //   this.props.currentItem.imageURL = null
    // }
  };

  handleFileDrop = (dropped) => {
    // console.log("drop", dropped);
    // let form = _.extend(this.state.form, {});
    // form.image = form.image.asMutable
    //   ? form.image.asMutable()
    //   : form.image;
    // form.image = dropped[0];
    // console.log("handleFileDrop", this.state.form, dropped[0]);
    this.setState({ image: dropped[0], cropClass: false, file: dropped[0] });
  };

  // handleFileDrop(acceptedFiles, rejectedFiles) {
  //   if (rejectedFiles.length) {
  //     this.setState({
  //       fileRejected: true
  //     });
  //   } else {
  //     this.setState({
  //       file: _.first(acceptedFiles),
  //       fileRejected: false
  //     });
  //   }
  // }

  handleSaveClick(e) {
    e.preventDefault();
    this.props.saveItem(
      _.extend(this.state, {
        form: _.extend(this.state.form, {
          documents: JSON.stringify(Object.keys(this.state.form.documents)),
          language_id: language.get(),
          marked_codes: JSON.stringify(this.state.form.marked_codes),
        }),
      })
    );
  }

  handleVerifyTemplate = async (event) => {
    event.preventDefault();
    if (_.size(this.state.form.template)) {
      let data = {};
      data.template = this.state.form.template;
      if (this.props.currentItem && this.props.currentItem.id) {
        data.id = this.props.currentItem.id;
      }
      await this.props.verifyTemplate({ ...data });
    } else {
      NotificationManager.success(
        "",
        strings.get("Client.notifications.validTemplate")
      );
    }
  };

  orderFieldsByStep(arr) {
    let y = [];
    _.map(arr, (field) => {
      if (!y[field["stepId"] - 1]) y[field["stepId"] - 1] = [];
      y[field["stepId"] - 1].push(field);
    });

    let newArr = [];
    y = _.map(y, (item) => {
      if (typeof item !== "undefined") newArr.push(item);
    });

    return newArr;
  }

  orderFieldsModalCancel() {
    /*let newFields = _.map(this.props.fields, (item) => {return item;});
    _.map(this.props.selectors,(selector) => {newFields.push(selector);});
    _.map(this.props.clauses,(clause) => {newFields.push(clause);});
    newFields = this.orderFieldsByStep(newFields); */
    this.refs.orderFieldsModal.hide();

    /*this.setState({
        fields: newFields,
    }); */
  }

  orderFieldsModalConfirm() {
    let { fieldsSize } = this.state;
    let newFields = {};
    let ind = 0;
    _.map(this.state.fields, (items) => {
      _.map(items, (item) => {
        let temp = {};
        temp.order = ind;
        if (fieldsSize[item.name]) {
          temp.size = parseInt(fieldsSize[item.name])
            ? parseInt(fieldsSize[item.name]) <
              this.state.fieldsPercentageStatus.minPer
              ? this.state.fieldsPercentageStatus.minPer
              : parseInt(fieldsSize[item.name])
            : this.state.fieldsPercentageStatus.minPer;
        } else {
          temp.size = 100;
        }
        newFields[item.name] = temp;
        ind++;
      });
    });
    if (!this.state.fieldsPercentageStatus.error) {
      this.orderStepsConfirm();
      this.props.updateItemOrder(newFields);
      this.refs.orderFieldsModal.hide();
    }
  }

  orderStepsConfirm() {
    let newSteps = [];
    _.map(this.state.steps, (step) => {
      newSteps.push(step.name);
    });
    this.props.updateStepOrder(newSteps);
  }

  handleCancelClick(e) {
    e.preventDefault();
    browserHistory.push("/documents");
  }

  handleSortEnd({ oldIndex, newIndex, index }) {
    // Changed By Nad.
    let stepItems = _.find(this.state.fields, ["stepId", index]);
    let stepItemsIndex = _.findIndex(this.state.fields, ["stepId", index]);
    // debugger;
    // console.log(
    //   "oldIndex",
    //   oldIndex,
    //   "newIndex",
    //   newIndex,
    //   stepItems[newIndex - 1].fromCodeBlock,
    //   stepItems[newIndex].fromCodeBlock,
    //   stepItems[newIndex + 1].fromCodeBlock,
    //   stepItems[oldIndex - 1].fromCodeBlock,
    //   stepItems[oldIndex].fromCodeBlock,
    //   stepItems[oldIndex + 1].fromCodeBlock
    // );
    // console.log((typeof(stepItems[newIndex - 1].fromCodeBlock) === "undefined" && stepItems[newIndex + 1].fromCodeBlock))
    // console.log((stepItems[newIndex - 1].fromCodeBlock && typeof(stepItems[newIndex + 1].fromCodeBlock) === "undefined"))
    if (
      newIndex === 0 ||
      newIndex === _.size(stepItems) - 1 ||
      !(
        (stepItems[newIndex] &&
          stepItems[newIndex].fromCodeBlock &&
          stepItems[newIndex + 1] &&
          stepItems[newIndex + 1].fromCodeBlock) ||
        (stepItems[newIndex] &&
          stepItems[newIndex].fromCodeBlock &&
          stepItems[newIndex - 1] &&
          stepItems[newIndex - 1].fromCodeBlock)
      )
    ) {
      let items = arrayMove(stepItems, oldIndex, newIndex);
      let newItems = [];
      _.map(this.state.fields, (field, ind) => {
        if (ind === stepItemsIndex) {
          newItems[ind] = items;
        } else {
          newItems[ind] = field;
        }
      });
      this.setState({ fields: newItems });
    }
  }

  handleTabsItemClick(id) {
    this.setState({ currentStepId: id });
  }

  handleStepOrderChange(preNextIndex, pointerObject) {
    let { steps } = this.state;
    let items = arrayMove(steps, preNextIndex.oldIndex, preNextIndex.newIndex);
    this.setState({
      steps: items,
    });
  }

  getCategoryTree(items, item, arr, depth = 0) {
    if (_.size(items) && _.find(items, item)) {
      if (item.parent_id && depth === 0) return;
      arr[0].push(item);
      arr[1].push(depth);
      _.map(items, (it) => {
        if (item.id === it.parent_id) {
          this.getCategoryTree(items, it, arr, depth + 1);
        }
      });
    }
  }

  saveGlossary(data) {
    let gloss = {};
    _.map(this.props.glossaries, (glossary) => {
      if (data.form.name === glossary.name) {
        gloss.id = glossary.id;
        gloss.name = glossary.name;
        return;
      }
    });
    if (gloss.id) {
      this.props.updateGlossary(gloss.id, data.form);
    } else {
      this.props.createGlossary(data.form);
    }
    this.hideRuleAddModal();
  }

  handleChange() {
    if (!this.state.form.read_more_link) {
      this.setState({
        checkedReadMore: !this.state.checkedReadMore,
      });
    }
  }

  handleChangeSharability() {
    let form = this.state.form;
    form.shareable = !form.shareable;
    this.setState({
      form,
    });
  }

  handleChangePublished() {
    let form = this.state.form;
    form.published = form.published === 1 ? 0 : 1;
    this.setState({
      form,
    });
  }

  handleRelatedDocuments(e) {
    let id = e.target.value;
    let docs = this.state.form.documents;

    let added =
      _.findIndex(Object.keys(docs), (item) => {
        return item === id;
      }) > -1;

    if (!added) {
      let { form, allDoc } = this.state;
      Object.keys(allDoc).map((index) => {
        if (allDoc[index].id === id)
          form.documents[allDoc[index].id] = allDoc[index];
      });
      this.setState({ form });
    }
  }

  handleCategoryRemove(data) {
    let { form } = this.state;
    if (form.documents[data]) {
      delete form.documents[data];
    }
    this.setState({ form });
  }

  handleFieldSize(data, element) {
    let { fieldsSize, fieldsPercentageStatus } = this.state;
    if (parseInt(data)) {
      if (parseInt(data) <= 100) fieldsSize[element.name] = data;
    } else {
      fieldsSize[element.name] = data;
    }
    if (parseInt(data) && parseInt(data) > fieldsPercentageStatus.minPer) {
      fieldsPercentageStatus.error = false;
    } else {
      fieldsPercentageStatus.error = true;
    }
    this.setState({ fieldsSize, fieldsPercentageStatus });
  }

  onScroll = (id) => {
    var myElement = document.getElementById(id);
    if (myElement) {
      document
        .getElementById("helpbox-container")
        .querySelectorAll(".active")
        .forEach((e) => e.classList.remove("active"));
      myElement.classList.add("active");
      var topPos = myElement.offsetTop;
      document.getElementById("helpbox-container").scrollTop = topPos;
    }
  };

  handleCopyCodesMarking = (text) => {
    let { form } = this.state;
    let result = strings.getFromBetween.get(text, "[[", "]]");
    for (const iterator of result) {
      if (!form.marked_codes.includes(iterator)) {
        form.marked_codes.push(iterator);
      }
    }
    this.setState({
      form,
    });
  };

  handleMarkedCodeRemove = (marked_codes) => {
    let { form } = this.state;
    form.marked_codes = marked_codes;
    this.setState({
      form,
    });
  };

  handleMarkedCodesFormat = () => {
    let { form } = this.state;
    if (typeof form.marked_codes === "string") {
      try {
        form.marked_codes = JSON.parse(form.marked_codes);
        //to do uncomment
        // if (typeof form.marked_codes === "string") form[key] = [];
      } catch (error) {
        form.marked_codes = [];
      }
    }
    this.setState({
      form,
    });
  };

  hideTemplateToolTipsModal = () =>{
    this.refs.tooltipModal.hide();
    this.setState({
      selectedItem: {}
    })
  }

  handleEditClick = (editToolTip) => {
    this.setState({editToolTip})
  }

  handleToolTipSaveClick = () =>{
  }
  render() {
    let selectedDocuments = _.map(this.state.form.documents, (item) => {
      if (typeof item !== "object") {
        return false;
      }
      return (
        <div key={`document-${item.id}`}>
          <ActiveLabel
            name={item.name}
            value={item.id}
            onRemove={this.handleCategoryRemove}
          />
        </div>
      );
    });
    if (typeof this.state.form.marked_codes === "string") {
      this.handleMarkedCodesFormat();
    }

    // if(!_.isEmpty(this.props.categories)){
    //   let language_id = this.props.categories[Object.keys(this.props.categories)[0]].language_id;
    //   if(language_id !== this.state.form.language_id){
    //     strings.getAllDocuments(language_id)
    //     .then( (allDoc) => { this.setState({allDoc},()=>{ this.loadRelatedDocuments() }) }, (error) => { 'error', error } );
    //   }
    // }

    const readMoreLink =
      this.state.checkedReadMore || this.state.form.read_more_link ? (
        <div className="form-group">
          <label className="col-sm-3 control-label">
            {strings.get("App.documents.documentForm.readMoreLink")}
          </label>
          <div className="col-sm-9">
            <input
              type="text"
              className="form-control"
              name="read_more_link"
              value={this.state.form.read_more_link}
              onChange={this.handleInputChange}
            />
          </div>
        </div>
      ) : null;
    let nameLabel = this.hasError("name")
      ? `${strings.get(
        "App.documents.documentForm.name"
      )} ${this.getErrorMessage("name")}`
      : strings.get("App.documents.documentForm.name");
    let priceLabel = this.hasError("price")
      ? `${strings.get(
        "App.documents.documentForm.price"
      )} ${this.getErrorMessage("price")}`
      : strings.get("App.documents.documentForm.price");
    let categoryLabel = this.hasError("category_id")
      ? `${strings.get(
        "App.documents.documentForm.category"
      )} ${this.getErrorMessage("category_id")}`
      : strings.get("App.documents.documentForm.category");
    let templateLabel = this.hasError("template")
      ? `${strings.get(
        "App.documents.documentForm.template"
      )} ${this.getErrorMessage("template")}`
      : strings.get("App.documents.documentForm.template");
    let imageLabel = this.hasError("image")
      ? strings.get("Exceptions.imageTooBig")
      : strings.get("App.documents.documentForm.image");
    let metaTitleLabel = this.hasError("meta_title")
      ? `${strings.get("App.articleForm.meta_title")} ${this.getErrorMessage(
        "meta_title"
      )}`
      : strings.get("App.articleForm.meta_title");
    let metaDescLabel = this.hasError("meta_description")
      ? `${strings.get(
        "App.articleForm.meta_description"
      )} ${this.getErrorMessage("meta_description")}`
      : strings.get("App.articleForm.meta_description");

    let arr = [[], []];
    _.map(this.props.categories, (item) => {
      this.getCategoryTree(this.props.categories, item, arr);
    });

    let categories = _.map(arr[0], (category, i) => {
      let sub = arr[1][i]
        ? " ".repeat(Math.round(arr[1][i] * 3 + arr[1][i] * 3)) + "|" + "_"
        : " ";
      return (
        <option
          className={`depth-${arr[1][i]}`}
          value={category.id}
          key={category.id}
        >
          {`${sub} ${category.name}`}
        </option>
      );
    });

    let arrDoc = [[], []];
    _.map(this.state.allDoc, (item) => {
      this.getCategoryTree(this.state.allDoc, item, arrDoc);
    });

    let documents = _.map(arrDoc[0], (document, i) => {
      return this.props.currentItem &&
        this.props.currentItem.id === document.id ? null : (
          <option
            className={`depth-${arr[1][i]}`}
            value={document.id}
            key={document.id}
          >
            {`${document.name}`}
          </option>
        );
    });

    let descriptionLabel = this.hasError("description")
      ? `${strings.get(
        "App.documents.documentForm.description"
      )} ${this.getErrorMessage("description")}`
      : strings.get("App.documents.documentForm.description");
    let priceFormGroup = this.state.form.payed ? (
      <div className={this.getErrorClass("price", "form-group")}>
        <label className="col-sm-3 control-label">{priceLabel}</label>
        <div className="col-sm-9">
          <input
            className="form-control"
            type="text"
            name="price"
            value={this.state.form.price}
            onChange={this.handleInputChange}
          />
        </div>
      </div>
    ) : null;

    let dropzoneContent = this.getPreviewImage() ? (
      this.getPreviewImage()
    ) : (
        <img src={"https://dummyimage.com/150x150/000/fff.jpg"} />
      );

    let templateErrors = this.getTemplateErrors();

    let sortableListsBySteps;
    if (_.size(this.state.fields)) {
      let stepFields = this.state.fields;
      _.map(stepFields, (fields, ind) => {
        stepFields[ind].stepId = parseInt(fields[0].stepId);
      });
      let listItems = _.find(stepFields, ["stepId", this.state.currentStepId]);

      sortableListsBySteps = (
        <div key={`item-${this.state.currentStepId}`}>
          <SortableList
            items={listItems}
            handleFieldSize={this.handleFieldSize}
            fieldsSize={this.state.fieldsSize}
            onSortEnd={({ oldIndex, newIndex }) => {
              this.handleSortEnd({
                oldIndex,
                newIndex,
                index: this.state.currentStepId,
              });
            }}
            axis="xy"
            useDragHandle
            lockToContainerEdges
            helperClass="SortableItem Field"
          />
        </div>
      );
    }
    let orderFieldsModalContent = (
      <div>
        <div className="modal-close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.orderFieldsModalCancel}
          />
        </div>
        <label>
          {strings.get("App.documents.documentForm.orderFieldsModal.title")}
        </label>
        <br />
        <StepTabs
          items={this.state.steps}
          currentItemId={this.state.currentStepId}
          fields={this.state.fields}
          onClick={this.handleTabsItemClick}
          glossaries={this.props.glossaries}
          // stepsOrder = {this.state.currItem && this.state.currItem.steps_order ? this.state.currItem.steps_order : ''}
          onStepOrderChange={this.handleStepOrderChange}
          expandGlossaries={this.state.expandGlossaries}
          glossariesCodeIdToName={this.state.glossariesCodeIdToName}
        />
        {sortableListsBySteps}
        {this.state.fieldsPercentageStatus.error ? (
          <div className="fieldSizeError">
            {strings.get(
              "App.documents.documentForm.orderFieldsModal.fieldSizeError"
            )}{" "}
            {this.state.fieldsPercentageStatus.minPer}%
          </div>
        ) : null}
        <button
          style={{ marginRight: ".4rem" }}
          className="btn btn-primary"
          onClick={this.orderFieldsModalConfirm}
        >
          {strings.get("App.documents.documentForm.orderFieldsModal.save")}
        </button>
        <button
          style={{ marginLeft: ".4rem" }}
          className="btn btn-default btn-color-change"
          onClick={this.orderFieldsModalCancel}
        >
          {strings.get("App.documents.documentForm.orderFieldsModal.cancel")}
        </button>
      </div>
    );

    const DropModal = () => {
      const { selectedItem, editToolTip } = this.state
      return (
      <CustomDropModal className="boron-modal" ref="tooltipModal">
        <div className="modal-close-btn1">
          <i
            className="ion-android-cancel clickable"
            onClick={this.hideTemplateToolTipsModal}
          />
        </div>
        <div className={`helpbox-item`}
          id={`${selectedItem.codeName}`}
          key={_.uniqueId()}
        >
          <h4> <b>{selectedItem.codeName}</b>
            {!editToolTip && 
            <Link to={`/glossaries/${selectedItem.glossary_id}/${selectedItem.codeName}`} target="_blank">
              <i
                className="ml-20 btn btn-default edit-btn ion-edit"
              />
            </Link>
            }
          </h4>  
             
          <br />
          {!editToolTip && (
            <>
              <h6>
                Name: 
                {selectedItem && selectedItem.name}
              </h6>
              <h6>
                Description:{" "}
                {selectedItem.description &&
                  JSON.parse(selectedItem.description).default || selectedItem.label &&
                  JSON.parse(selectedItem.label).default}
              </h6>
              <h6>
                Glossary:
                {selectedItem.glossary && selectedItem.glossary.name}
              </h6>
            </>
          )}

          {editToolTip && 
            <form className="col-sm-12 col-md-12">
              <div className='form-group' >
                <label className="col-sm-3 control-label">Name: </label>
                <div className="col-sm-9">
                  <input className="form-control" type="text" name="female"  />
                </div>
              </div>
              <div className='form-group' >
                <label className="col-sm-3 control-label">Description: </label>
                <div className="col-sm-9">
                  <input classÍName="form-control" type="text" name="female"  />
                </div>
              </div>
              <div className='form-group' >
                <label className="col-sm-3 control-label">Glossary: </label>
                <div className="col-sm-9">
                  <input className="form-control" type="text" name="female"  />
                </div>
              </div>
              <div className="form-actions">
                <button className="btn btn-primary" onClick={ this.handleToolTipSaveClick }>{strings.get('App.grammar.genderForm.save')}</button>
                <button className="btn btn-default" onClick={()=> this.handleEditClick(false) }>{strings.get('App.grammar.genderForm.cancel')}</button>
              </div>
            </form>
          }
        </div>
    </CustomDropModal>
    )}
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="DocumentForm">
            <CustomDropModal className="boron-modal" ref="orderFieldsModal">
              {orderFieldsModalContent}
            </CustomDropModal>
            <CustomDropModal className="boron-modal" ref="ruleAddModal">
              <div className="modal-close-btn">
                <i
                  className="ion-android-cancel clickable"
                  onClick={this.hideRuleAddModal}
                />
              </div>
              <GlossaryForm
                exceptions={this.props.exceptions}
                categories={this.props.categories}
                glossaries={this.props.glossaries}
                steps={this.props.steps}
                saveItem={this.saveGlossary}
                currentRule={this.state.currentRule}
                hideAddRuleModal={this.hideRuleAddModal}
              />
            </CustomDropModal>

            <form className="form-horizontal">
              <div className="panel panel-default">
                <div className="panel-heading">
                  <b>{this.props.formHeading}</b>
                </div>
                <div className="panel-body">
                  <div className={this.getErrorClass("name", "form-group")}>
                    <label className="col-sm-3 control-label">
                      {nameLabel}
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        value={this.state.form.name}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="col-sm-3 control-label">
                      {/* Version Type */}
                      {strings.get("App.documents.documentForm.versionType")}
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        name="version_type"
                        onChange={this.handleInputChange}
                        value={this.state.form.version_type}
                      >
                        <option value="major">
                          {" "}
                          {strings.get("App.documents.documentForm.major")}{" "}
                        </option>
                        <option value="minor">
                          {" "}
                          {strings.get("App.documents.documentForm.minor")}{" "}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div
                    className={this.getErrorClass("category_id", "form-group")}
                  >
                    <label className="col-sm-3 control-label">
                      {categoryLabel}
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        name="category_id"
                        value={this.state.form.category_id}
                        onChange={ (e) => {
                            this.handleInputChange(e);
                            this.getSuggesions(e.target.value);
                          }
                        }
                      >
                        <option value="" disabled>
                          {strings.get("App.documents.documentForm.choose")}
                        </option>
                        {categories}
                      </select>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="col-sm-3 control-label">
                      {strings.get("App.documents.documentForm.payed")}
                    </label>
                    <div className="col-sm-9">
                      <Switch
                        enabled={!!this.state.form.payed}
                        onChange={this.handlePayedChange}
                      />
                    </div>
                  </div>
                  {priceFormGroup}

                  {/* TODO: Need to change. Don't support all 3 languages */}
                  {/* Hide by Nad. This On/Off Switch displayed in the list page, So to avoid the confusion the below code hidden */}
                  {/*
                    {this.props.currentItem ?
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          {strings.get('App.categoryForm.visibility.title')}
                        </label>
                        <div className="col-sm-9">
                          <select className="form-control" name="published"
                                  value={this.state.form.published || this.props.currentItem.published}
                                  onChange={this.handleInputChange}>
                            <option value="1">Publish</option>
                            <option value="0">Unpublish</option>
                          </select>
                        </div>

                      </div> : ''
                    } */}

                  {/* <div className={this.getErrorClass("image", "form-group")}>
                    <label className="col-sm-3 control-label">
                      {imageLabel}
                    </label>
                    <div className="col-sm-9">
                      <Dropzone
                        className="dropzone"
                        onDrop={this.handleFileDrop}
                        multiple={false}
                        maxSize={4096000}
                        accept="image/*"
                      >
                        {dropzoneContent}
                      </Dropzone>
                    </div>
                  </div> */}

                  <div className="form-group">
                    <label className="col-sm-3 control-label">
                      {imageLabel}
                    </label>

                    <div className="col-sm-9">
                      {this.state.image || this.state.file ? (
                        <input
                          type="button"
                          className="close-btn"
                          style={{ marginTop: -10 }}
                          onClick={this.removeImage("image", "file")}
                          value="X"
                        />
                      ) : this.isImage("file") ? (
                        <input
                          type="button"
                          className="close-btn"
                          style={{ marginTop: -10 }}
                          onClick={this.setImage("file")}
                          value="X"
                        />
                      ) : (
                            ""
                          )}
                      <Dropzone
                        className="dropzone"
                        onDrop={this.handleFileDrop}
                        multiple={false}
                        maxSize={4096000}
                        accept="image/*"
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <>
                              {dropzoneContent}

                              {this.state.image ? (
                                <ReactAvatarEditor
                                  scale={parseFloat(this.state.scale)}
                                  rotate={0}
                                  className="image-placeholder"
                                  image={this.state.image}
                                  width={150}
                                  height={150}
                                  ref={(ref) => this.setEditorRef(ref)}
                                />
                              ) : this.state.file && this.state.currItem ? (
                                <img src={this.state.file} />
                              ) : (
                                    ""
                                  )}
                            </>
                          </div>
                        )}
                        {/* {dropzoneContent}

                        {this.state.image ? (
                          <ReactAvatarEditor
                            scale={this.state.scale}
                            rotate={0}
                            className="image-placeholder"
                            image={this.state.image}
                            width={150}
                            height={150}
                            ref={(ref) => this.setEditorRef(ref)}
                          />
                        ) : this.state.file && this.state.currItem ? (
                          <img src={this.state.file} />
                        ) : (
                          ""
                        )} */}
                      </Dropzone>

                      {this.state.cropClass === false ? (
                        <div className="help-block">
                          <div className="help-block">
                            ( {strings.get("App.companyForm.suggestDimension")}{" "}
                            )
                          </div>
                          {this.state.image !== null ? (
                            <input
                              type="range"
                              step="0.01"
                              min="0.1"
                              max="2"
                              name="scale"
                              value={this.state.scale}
                              onChange={(e) =>
                                this.setState({
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                          ) : (
                              ""
                            )}

                          {this.state.image !== null ? (
                            <input
                              type="button"
                              className="crop-image-btn"
                              onClick={this.cropImage}
                              value="Crop"
                            />
                          ) : (
                              ""
                            )}
                        </div>
                      ) : (
                          ""
                        )}
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="col-sm-3 control-label">
                      {strings.get(
                        "App.documents.documentForm.addReadMoreLink"
                      )}
                    </label>
                    <div className="col-sm-9">
                      <Switch
                        enabled={
                          !!this.state.checkedReadMore ||
                          !!this.state.form.read_more_link
                        }
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  {readMoreLink}

                  <div className="form-group">
                    <label className="col-sm-3 control-label">
                      {strings.get("App.documents.documentForm.shareable")}
                    </label>
                    <div className="col-sm-9">
                      <Switch
                        enabled={!!this.state.form.shareable}
                        onChange={this.handleChangeSharability}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="col-sm-3 control-label">
                      {strings.get("App.documents.publish")}
                    </label>
                    <div className="col-sm-9">
                      <Switch
                        enabled={this.state.form.published === 1 ? true : false}
                        onChange={this.handleChangePublished}
                      />
                    </div>
                  </div>

                  <div
                    className={this.getErrorClass(
                      "descriptionLabel",
                      "form-group"
                    )}
                  >
                    <label className="col-sm-3 control-label">
                      {descriptionLabel}
                    </label>
                    {/* <textarea className="form-control" name="description" value={ this.state.form.description } onChange={ this.handleInputChange } /> */}
                    <div className="col-sm-9">
                      <Editor
                        content={this.state.form.description}
                        handleChange={this.handleEditorChange}
                        height={100}
                      />
                    </div>
                  </div>

                  <div
                    className={this.getErrorClass(
                      "descriptionLabel",
                      "form-group"
                    )}
                  >
                    <label className="col-sm-3 control-label">
                      {strings.get("App.documents.documentForm.helpBox")}
                    </label>
                    <div className="col-sm-9">
                      <Helpbox
                        form={this.state.form}
                        itemElements={this.props.itemElements}
                      />
                    </div>
                  </div>

                  <WorkFlowCodes
                    workflows={this.props.workflows}
                    fetchCodesFromWorkFlows={this.props.fetchCodesFromWorkFlows}
                    fetchCodesFromDocs={this.props.fetchCodesFromDocs}
                    glossariesCodeIdToName={this.state.glossariesCodeIdToName}
                    expandGlossaries={this.state.expandGlossaries}
                    handleCopyCodesMarking={this.handleCopyCodesMarking}
                    marked_codes={this.state.form.marked_codes}
                    handleMarkedCodeRemove={this.handleMarkedCodeRemove}
                    allDocs={this.state.allDoc}
                  />

                  <div className="form-group">
                    <label className="col-sm-3 control-label">
                      {strings.get(
                        "App.documents.documentForm.labelPreference.title"
                      )}
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        name="label_preference"
                        onChange={this.handleInputChange}
                        value={this.state.form.label_preference}
                      >
                        <option value="default">
                          {" "}
                          {strings.get(
                            "App.documents.documentForm.labelPreference.default"
                          )}{" "}
                        </option>
                        <option value="me">
                          {" "}
                          {strings.get(
                            "App.documents.documentForm.labelPreference.me"
                          )}{" "}
                        </option>
                        <option value="client">
                          {" "}
                          {strings.get(
                            "App.documents.documentForm.labelPreference.client"
                          )}{" "}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className={this.getErrorClass("template", "form-group")}>
                    <label className="col-sm-3 control-label">
                      {templateLabel}
                    </label>
                    <div className="col-sm-9">
                      {templateErrors}
                      <Editor
                        content={this.state.form.template}
                        handleKeyUp={this.handleTemplateChange}
                        handleChange={() => { }}
                        onScroll={this.onScroll}
                        isHasHelpbox
                        fieldSuggest={this.state.fieldSuggest}
                        codeSuggest={this.state.codeSuggest}
                        onClick={this.handlePopup}
                      />
                      {DropModal()}
                      {/* <Mention
                      delimiter={'['}
                      dataSource={[
                          'testing 1',
                          'testing 2',
                          'testing 3',
                          'testing 4'
                        ]}
                      /> */}
                    </div>
                  </div>

                  <div
                    className={this.getErrorClass("meta_title", "form-group")}
                  >
                    <label className="col-sm-3 control-label">
                      {metaTitleLabel}
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="text"
                        name="meta_title"
                        value={this.state.form.meta_title}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>

                  <div
                    className={this.getErrorClass(
                      "meta_description",
                      "form-group"
                    )}
                  >
                    <label className="col-sm-3 control-label">
                      {metaDescLabel}
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        className="form-control"
                        name="meta_description"
                        value={this.state.form.meta_description}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>

                  <div
                    className={this.getErrorClass("meta_keyword", "form-group")}
                  >
                    <label className="col-sm-3 control-label">
                      {strings.get("App.articleForm.meta_keyword")}
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="text"
                        name="meta_keyword"
                        value={this.state.form.meta_keyword}
                        onChange={this.handleInputChange}
                      />
                      <div className="help-block">
                        {strings.get("App.articleForm.meta_keyword_example")}
                      </div>
                    </div>
                  </div>

                  <div
                    className={this.getErrorClass("category_id", "form-group")}
                  >
                    <label className="col-sm-3 control-label">
                      {" "}
                      {strings.get(
                        "App.documents.documentForm.related_documents"
                      )}{" "}
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        name="category_id"
                        value=""
                        onChange={this.handleRelatedDocuments}
                      >
                        <option value="" disabled>
                          {strings.get("App.documents.documentForm.choose")}
                        </option>
                        {documents}
                      </select>
                    </div>
                    <div className="col-sm-3"></div>
                    <div
                      className="col-sm-9"
                      style={{
                        display: "inline-flex",
                        marginTop: "10px",
                        flexFlow: "wrap",
                      }}
                    >
                      {selectedDocuments}
                    </div>
                  </div>
                </div>
                <footer className="panel-footer text-right bg-light lter">
                  <button
                    className="btn btn-primary"
                    onClick={this.handleVerifyTemplate}
                  >
                    {strings.get("App.documents.documentForm.validate")}
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={this.handleSaveClick}
                  >
                    {strings.get("App.documents.documentForm.save")}
                  </button>
                  <button
                    className="btn btn-default btn-color-change"
                    onClick={this.handleCancelClick}
                  >
                    {strings.get("App.documents.documentForm.cancel")}
                  </button>
                </footer>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

DocumentForm.propTypes = {
  exceptions: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  saveItem: PropTypes.func.isRequired,
  verifyTemplate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    carousels: documentsSelectors.getCarousels(state)
      ? documentsSelectors.getCarousels(state)
      : {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    verifyTemplate: (data) => {
      dispatch(documentsActions.verifyTemplate(data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentForm);

export function getModal() {
  return modal;
}

export function getTemplateData() {
  return templateData;
}
