import React, { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "react-autobind";
import strings from "../../services/strings";
import language from "../../services/language";
import _ from "lodash";

// import './DocumentSorter.scss';

class UserSorter extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      column : "name",
      pageLimit: 10,
      descending: true
    };
  }

  getSorter() {
    if (this.props.sorter) {
      return this.props.sorter.column;
    }

    return "";
  }

  handleChange(e) {
    let sorter = {
      column: e.target.value,
      descending: true,
      pageLimit: this.state.pageLimit,
    };
    if (sorter.column === "name") {
      sorter.descending = false;
    }
    this.setState({
      column : sorter.column,
      descending: sorter.descending,
      pageLimit : sorter.pageLimit
    });
    this.props.setSorter(sorter);
    this.props.fetchItems(true);
  }

  getSorterPageSize() {
    if (this.props.sorter) {
      return this.props.sorter.pageLimit;
    }
    return "";
  }

  handleChangePageSize(e) {
    let sorter = {
      column: this.state.column,
      descending: this.state.descending,
      pageLimit: e.target.value
    };
    this.setState({
      pageLimit : sorter.pageLimit
    });
    this.props.setSorter(sorter);
    this.props.fetchItems(true);
  }

  handleOrder = () => {
    let sorter = {
      descending: !this.props.sorter.descending
    }
    this.props.setSorter(sorter);
    this.props.fetchItems(true);
}

  render() {
    return (
      <div className="DocumentSorter">
        <label>{strings.get("App.users.sorter.title")}</label>
        <select
          className="form-control"
          name="sorter"
          value={this.getSorter()}
          onChange={this.handleChange}
        >
          <option value="first_name">
            {strings.get("App.users.sorter.name")}
          </option>
          <option value="created_at">
            {strings.get("App.users.sorter.registered")}
          </option>
          <option value="updated_at">
            {strings.get("App.users.sorter.login")}
          </option>
          {/* <option value="user_name">
            {strings.get("App.users.sorter.user")}
          </option>
          <option value="blocked">
            {strings.get("App.users.sorter.blocked")}
          </option>
          <option value="user_id">
            {strings.get("App.users.sorter.userId")}
          </option>
          <option value="email">
            {strings.get("App.users.sorter.email")}
          </option> */}
        </select>

        <span className="ml-5" style={{color:'#000000'}} onClick={this.handleOrder}>
          {this.props.sorter.descending ?
            (<i title="Set to Ascending Direction" class="fa fa-arrow-down"></i>) 
              : 
            (<i title="Set to Descending Direction" class="fa fa-arrow-up"></i>)
          }
        </span>

        <select
          className="form-control"
          name="sorter"
          value={this.getSorterPageSize()}
          onChange={this.handleChangePageSize}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
    );
  }
}

UserSorter.propTypes = {
  sorter: PropTypes.object,
  setSorter: PropTypes.func.isRequired,
  fetchItems: PropTypes.func.isRequired,
};

export default UserSorter;
