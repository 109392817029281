// import React, { Component } from 'react';
// import strings from "../../../services/strings";

// import Modal from 'reboron/DropModal';

// class Popup extends Component {

//   showDeleteModal = () => this.refs.deleteModal.show();

//   hideDeleteModal = () => this.refs.deleteModal.hide()

//   handleDeleteClick = (id) => {
//     this.props.setCurrentItemId(id);
//     this.showDeleteModal();
//   }

//   handleConfirmDeleteClick = () => {
//     this.props.deleteItem(this.props.currentItem.id);
//     _.delay(() => this.props.unsetCurrentItemId(), 250);
//     this.hideDeleteModal();
//   }

//   handleCancelDeleteClick = () => {
//     _.delay(() => this.props.unsetCurrentItemId(), 250);
//     this.hideDeleteModal();
//   }
//   render() {
//     let deleteModalContent = this.props.currentItem ? (
//       <span>
//         <div className="modal-close-btn">
//           <i
//             className="ion-android-cancel clickable"
//             onClick={this.handleCancelDeleteClick}
//           />
//         </div>
//         <h2>
//           {strings.get("App.deleteModal.message", {
//             itemName: this.props.currentItem.name
//           })}
//           {/* udfvmdvdfvbkdvdvhdifvhdifhvihihi */}
//         </h2>
//         <div className="form-actions">
//           <button
//             className="btn btn-lg btn-danger"
//             onClick={this.handleConfirmDeleteClick}
//           >
//             {strings.get("App.deleteModal.delete")}
//           </button>
//           <button
//             className="btn btn-lg btn-default cancel"
//             onClick={this.handleCancelDeleteClick}
//           >
//             {strings.get("App.deleteModal.cancel")}
//           </button>
//         </div>
//       </span>
//     ) : null;
//     return (
//       <div>
//         <Modal className="boron-modal" ref={this.props.reference}>
//           {deleteModalContent}
//         </Modal>
//       </div>
//     );
//   }
// }

// export default Popup

import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import DropModal from "reboron/DropModal";
import FadeModal from "reboron/FadeModal";

export default class CustomDropModal extends Component {
  handleOnShow = () => {
    document.querySelector("body").style.overflow = "hidden";
    if (this.props && typeof this.props.onShow === "function") {
      this.props.onShow();
    }
  };

  handleOnHide = () => {
    if (!_.size(document.querySelectorAll(".boron-modal")))
      document.querySelector("body").style.overflow = "auto";
    if (this.props && typeof this.props.onHide === "function") {
      this.props.onHide();
    }
  };

  show = () => {
    this.refs.refrence.show();
  };

  hide = () => {
    this.refs.refrence.hide();
  };

  render() {
    return (
      <DropModal
        className={this.props.className ? this.props.className : ""}
        ref={"refrence"}
        backdrop={this.props.backdrop}
        keyboard={this.props.keyboard}
        onShow={this.handleOnShow}
        onHide={this.handleOnHide}
      >
        {this.props.children}
      </DropModal>
    );
  }
}

CustomDropModal.propTypes = {
  className: PropTypes.string.isRequired,
  onHide: PropTypes.func,
  onShow: PropTypes.func,
  backdrop: PropTypes.any,
  keyboard: PropTypes.bool,
  children: PropTypes.any,
};

CustomDropModal.defaultProps = {
  keyboard: true,
};

export class CustomFadeModal extends Component {
  handleOnShow = () => {
    document.querySelector("body").style.overflow = "hidden";
    if (typeof this.props.onShow === "function") {
      this.props.onShow();
    }
  };

  handleOnHide = () => {
    if (!_.size(document.querySelectorAll(".boron-modal")))
      document.querySelector("body").style.overflow = "auto";
    if (typeof this.props.onHide === "function") {
      this.props.onHide();
    }
  };

  show = () => {
    this.refs.refrence.show();
  };

  hide = () => {
    this.refs.refrence.hide();
  };

  render() {
    return (
      <FadeModal
        className={this.props.className ? this.props.className : ""}
        ref={"refrence"}
        backdrop={this.props.backdrop}
        keyboard={this.props.keyboard}
        onShow={this.handleOnShow}
        onHide={this.handleOnHide}
      >
        {this.props.children}
      </FadeModal>
    );
  }
}

CustomFadeModal.propTypes = {
  className: PropTypes.string.isRequired,
  onHide: PropTypes.func,
  onShow: PropTypes.func,
  backdrop: PropTypes.any,
  keyboard: PropTypes.bool,
  children: PropTypes.any,
};

CustomFadeModal.defaultProps = {
  keyboard: true,
};
