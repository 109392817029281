import React, { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import language from "../../services/language";
import { Link } from "react-router-dom";
import _ from "lodash";
import Select from "react-select";
import ActiveLabel from "../ActiveLabel";
import { browserHistory } from "../../helpers";

class StepForm extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      currentItemLoaded: false,
      form: {
        language_id: language.get(),
        name: "",
        order: "",
        categories: [],
      },
    };
  }

  componentDidMount() {
    this.tryLoadCurrentItem();
  }

  componentDidUpdate() {
    this.tryLoadCurrentItem();
  }

  tryLoadCurrentItem() {
    if (this.props.currentItem && !this.state.currentItemLoaded) {
      let form = _.extend({}, this.state.form);
      _.map(this.state.form, (value, key) => {
        if (key === "categories") {
          try {
            form[key] = this.props.currentItem[key].split(",");
            if (form[key] && form[key][0] === "") {
              form[key] = [];
            }
          } catch (error) {
            console.log("error");
            form[key] = [];
          }
        } else {
          form[key] = this.props.currentItem[key]
            ? this.props.currentItem[key]
            : this.state.form[key];
        }
      });
      this.setState({
        currentItemLoaded: true,
        form,
      });
    }
  }

  hasError(inputName) {
    return !!this.props.exceptions[inputName];
  }

  getErrorClass(inputName, defaultClasses = "") {
    return this.hasError(inputName)
      ? defaultClasses + " has-error"
      : defaultClasses;
  }

  getErrorMessage(inputName) {
    return this.props.exceptions[inputName];
  }

  handleInputChange(e) {
    let form = {};
    form[e.target.name] = e.target.value;
    this.setState({
      form: _.extend(this.state.form, form),
    });
  }

  handleSaveClick(e) {
    e.preventDefault();
    this.props.saveItem(
      _.extend(this.state, {
        form: _.extend(this.state.form, {
          language_id: language.get(),
        }),
      })
    );
  }

  handleCancelClick(e) {
    e.preventDefault();
    browserHistory.push("/glossaries/steps");
  }

  listCategories = () => {
    let categories = this.props.categories;
    let selectors = [];
    selectors.push({
      value: 0,
      label: strings.get("App.glossaries.glossaryForm.all"),
    });
    _.each(categories, (category) => {
      selectors.push({
        value: category.id,
        label: category.name,
      });
    });
    return selectors;
  };

  handleCategorySelect = (event) => {
    let id = `${event.value}`;
    let { form } = this.state;
    if (!form.categories.includes(id)) {
      form.categories.push(id);
    }
    if (id === "0") {
      form.categories = [];
    }
    this.setState({ form });
  };

  handleCategoryRemove = (data) => {
    let { form } = this.state;
    if (typeof data === "number") {
      data = `${data}`;
    }
    if (form.categories.includes(data)) {
      form.categories.splice(form.categories.indexOf(data), 1);
    }
    this.setState({ form });
  };

  render() {
    let nameLabel = this.hasError("name")
      ? `${strings.get("App.glossaries.steps.name")} ${this.getErrorMessage(
          "name"
        )}`
      : strings.get("App.glossaries.steps.name");

    let categoriesOptions = this.listCategories();

    let selectedCategories = _.map(this.state.form.categories, (item) => {
      if (item === 0) {
        return false;
      }
      if (_.size(this.props.categories) && item)
        return (
          <ActiveLabel
            name={this.props.categories[`_${item}`].name}
            value={this.props.categories[`_${item}`].id}
            onRemove={this.handleCategoryRemove}
            key={`categories-${this.props.categories[`_${item}`].id}`}
          />
        );
    });
    let categories = (
      <div className="form-group">
        <label className="control-label">
          {strings.get("App.documents.documentForm.category")}
        </label>
        <Select
          options={categoriesOptions}
          name="allCategories"
          value={categoriesOptions.value}
          onChange={this.handleCategorySelect}
          placeholder={strings.get("App.glossaries.glossaryForm.all")}
        />
        <div style={{ marginTop: "10px" }}>{selectedCategories}</div>
      </div>
    );

    return (
      <div className="StepForm row">
        <form>
          <div className="col-sm-12 col-md-6">
            <div className={this.getErrorClass("name", "form-group")}>
              <label className="control-label">{nameLabel}</label>
              <input
                className="form-control"
                type="text"
                name="name"
                value={this.state.form.name}
                onChange={this.handleInputChange}
              />
            </div>
            {categories}
            <div className="form-actions">
              <button
                className="btn btn-primary"
                onClick={this.handleSaveClick}
              >
                {strings.get("App.glossaries.steps.save")}
              </button>
              <button
                className="btn  btn-default btn-color-change"
                onClick={this.handleCancelClick}
              >
                {strings.get("App.glossaries.steps.cancel")}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

StepForm.propTypes = {
  exceptions: PropTypes.object.isRequired,
  saveItem: PropTypes.func.isRequired,
};

export default StepForm;
