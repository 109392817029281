import "whatwg-fetch";
import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import DocumentListPage from "./containers/documents/DocumentListPage";
import DocumentAddPage from "./containers/documents/DocumentAddPage";
import DocumentEditPage from "./containers/documents/DocumentEditPage";

import GlossaryListPage from "./containers/glossaries/GlossaryListPage";
import GlossaryAddPage from "./containers/glossaries/GlossaryAddPage";
import GlossaryImportPage from "./containers/glossaries/GlossaryImportPage";
import GlossaryEditPage from "./containers/glossaries/GlossaryEditPage";

import WorkflowCategoriesListPage from "./containers/workflowCategories/WorkflowCategoriesListPage";
import WorkflowCategoriesEditPage from "./containers/workflowCategories/WorkflowCategoriesEditPage";
import WorkflowCategoriesAddPage from "./containers/workflowCategories/WorkflowCategoriesAddPage";

import WorkflowAddPage from "./containers/workflows/WorkflowsAddPage";
import WorkflowEditPage from "./containers/workflows/WorkflowsEditPage";
import WorkflowListPage from "./containers/workflows/WorkflowsListPage";

import StepListPage from "./containers/steps/StepListPage";
import StepAddPage from "./containers/steps/StepAddPage";
import StepEditPage from "./containers/steps/StepEditPage";
import StepViewPage from "./containers/steps/StepViewPage";

import CategoryListPage from "./containers/categories/CategoryListPage";
import CategoryAddPage from "./containers/categories/CategoryAddPage";
import CategoryEditPage from "./containers/categories/CategoryEditPage";

import CompanyCategoryListPage from "./containers/companyCategories/CompanyCategoryListPage";
import CompanyCategoryAddPage from "./containers/companyCategories/CompanyCategoryAddPage";
import CompanyCategoryEditPage from "./containers/companyCategories/CompanyCategoryEditPage";

import CompanyListPage from "./containers/companies/CompanyListPage";
import CompanyAddPage from "./containers/companies/CompanyAddPage";
import CompanyEditPage from "./containers/companies/CompanyEditPage";
import UserEmailVerificationPage from "./containers/settings/email/UserEmailVerificationPage";
import OtpEmailPage from "./containers/settings/email/OtpEmailPage";
import GenderStringListPage from "./containers/grammar/genderStrings/GenderStringListPage";
import GenderStringAddPage from "./containers/grammar/genderStrings/GenderStringAddPage";
import GenderStringEditPage from "./containers/grammar/genderStrings/GenderStringEditPage";

import GroupListPage from "./containers/groups/GroupListPage";
import GroupAddPage from "./containers/groups/GroupAddPage";
import GroupEditPage from "./containers/groups/GroupEditPage";

import LanguageEditPage from "./containers/settings/languages/LanguageEditPage";
import LanguageAddPage from "./containers/settings/languages/LanguageAddPage";
import LanguageListPage from "./containers/settings/languages/LanguageListPage";
import EmailPage from "./containers/settings/email/EmailPage";
import MilestoneExpireEmailPage from "./containers/settings/email/MilestoneExpireEmailPage";
import TemplateNotes from "./containers/settings/templateNotes/TemplateNotes";
import ShareEmailPage from "./containers/settings/email/ShareEmailPage";
import ShareMessagePage from "./containers/settings/email/ShareMessagePage";
import ShareConceptEmailPage from "./containers/settings/email/ShareConceptEmailPage";
import DownloadReminderEmailPage from "./containers/settings/email/DownloadReminderEmailPage";
import DraftReminderEmailPage from "./containers/settings/email/DraftReminderEmailPage";
import SmtpSettingPage from "./containers/settings/email/SmtpSettingPage";
import SearchPlaceholderPage from "./containers/settings/placeholder/SearchPlaceholderPage";
import ContactSettingPage from "./containers/settings/contact/ContactSettingPage";
import MaintenanceSettingPage from "./containers/settings/maintenance/MaintenanceSettingPage";
import IpAddressesAddPage from "./containers/settings/ipAddresses/IpAddressesAddPage";
import IpAddressesEditPage from "./containers/settings/ipAddresses/IpAddressesEditPage";
import IpAddressesListPage from "./containers/settings/ipAddresses/IpAddressesListPage";
import CookieMessagePage from "./containers/settings/maintenance/CookieMessagePage";
import UserWelcomeEmailPage from "./containers/settings/email/UserWelcomeEmailPage";
import UserWelcomeAdminEmailPage from "./containers/settings/email/UserWelcomeAdminEmailPage";
import ForgotPasswordEmailPage from "./containers/settings/email/ForgotPasswordEmailPage";

import UserListPage from "./containers/users/UserListPage";
import UserEditPage from "./containers/users/UserEditPage";
import UserAddPage from "./containers/users/UserAddPage";
import UserDraftPage from "./containers/users/UserDraftPage";
import UserWorkflowPage from "./containers/users/UserWorkflowPage";
import UserSharedDraftPage from "./containers/users/UserSharedDraftPage";
import UserDownloadPage from "./containers/users/UserDownloadPage";
import UserShareEmailPage from "./containers/users/UserShareEmailPage";
import UserShareDraftInstructionPage from "./containers/users/UserShareDraftInstructionPage";
import UserAlertPage from "./containers/users/UserAlertPage";
import UserRequiredOptionPage from "./containers/users/UserRequiredOptionPage";
import UserRoleDraftPage from "./containers/users/UserRoleDraftPage";

import BlockedUsersEditPage from "./containers/blockedUsers/BlockedUsersEditPage";
import BlockedUsersListPage from "./containers/blockedUsers/BlockedUsersListPage";

import BlockedGroupsListPage from "./containers/blockedGroups/BlockedGroupsListPage";
import BlockedGroupsEditPage from "./containers/blockedGroups/BlockedGroupsEditPage";

import PartnersListPage from "./containers/partners/PartnersListPage";
import PartnersEditPage from "./containers/partners/PartnersEditPage";
import PartnersAddPage from "./containers/partners/PartnersAddPage";

import ArticlesListPage from "./containers/articles/ArticlesListPage";
import ArticlesEditPage from "./containers/articles/ArticlesEditPage";
import ArticlesAddPage from "./containers/articles/ArticlesAddPage";

import ArticleCategoriesListPage from "./containers/articleCategories/ArticleCategoriesListPage";
import ArticleCategoriesEditPage from "./containers/articleCategories/ArticleCategoriesEditPage";
import ArticleCategoriesAddPage from "./containers/articleCategories/ArticleCategoriesAddPage";

import TranslationListPage from "./containers/settings/translations/TranslationListPage";
import TranslationAddPage from "./containers/settings/translations/TranslationAddPage";
import TranslationEditPage from "./containers/settings/translations/TranslationEditPage";
import TranslationViewPage from "./containers/settings/translations/TranslationViewPage";

export const Routes = () => (
  <Switch>
    <Route exact path="/documents/categories" component={CategoryListPage} />
    <Route exact path="/documents/categories/add" component={CategoryAddPage} />
    <Route
      exact
      path="/documents/categories/:id"
      component={CategoryEditPage}
    />

    <Route exact path={["/", "/documents"]} component={DocumentListPage} />
    <Route exact path="/documents/add" component={DocumentAddPage} />
    <Route exact path="/documents/:id" component={DocumentEditPage} />

    <Route exact path="/glossaries/steps" component={StepListPage} />
    <Route exact path="/glossaries/steps/add" component={StepAddPage} />
    <Route exact path="/glossaries/steps/:id" component={StepEditPage} />
    <Route exact path="/glossaries/steps/:id/view" component={StepViewPage} />

    <Route
      exact
      path="/glossaries/companies/categories"
      component={CompanyCategoryListPage}
    />
    <Route
      exact
      path="/glossaries/companies/categories/add"
      component={CompanyCategoryAddPage}
    />
    <Route
      exact
      path="/glossaries/companies/categories/:id"
      component={CompanyCategoryEditPage}
    />

    <Route exact path="/glossaries/companies" component={CompanyListPage} />
    <Route exact path="/glossaries/companies/add" component={CompanyAddPage} />
    <Route exact path="/glossaries/companies/:id" component={CompanyEditPage} />

    <Route exact path="/glossaries" component={GlossaryListPage} />
    <Route exact path="/glossaries/add" component={GlossaryAddPage} />
    <Route exact path="/glossaries/import" component={GlossaryImportPage} />
    <Route exact path="/glossaries/:id" component={GlossaryEditPage} />
    <Route exact path="/glossaries/:id/:code" component={GlossaryEditPage} />

    <Route exact path="/settings/userEmailVerification" component={UserEmailVerificationPage} />

    <Route exact path="/settings/otpEmail" component={OtpEmailPage} />

    <Route
      exact
      path="/workflows/categories"
      component={WorkflowCategoriesListPage}
    />
    <Route
      exact
      path="/workflows/categories/add"
      component={WorkflowCategoriesAddPage}
    />
    <Route
      exact
      path="/workflows/categories/:id"
      component={WorkflowCategoriesEditPage}
    />

    <Route exact path="/workflows/add" component={WorkflowAddPage} />
    <Route exact path="/workflows/edit/:id" component={WorkflowEditPage} />
    <Route exact path="/workflows" component={WorkflowListPage} />

    <Route path="/grammar">
      <Route path="/gender-strings" component={GenderStringListPage} />
      <Route path="/gender-strings/add" component={GenderStringAddPage} />
      <Route path="/gender-strings/:id" component={GenderStringEditPage} />
    </Route>

    <Route
      exact
      path="/users/groups/blockedGroups"
      component={BlockedGroupsListPage}
    />
    <Route
      exact
      path="/users/groups/blockedGroups/:id"
      component={BlockedGroupsEditPage}
    />

    <Route exact path="/users/groups" component={GroupListPage} />
    <Route exact path="/users/groups/add" component={GroupAddPage} />
    <Route exact path="/users/groups/:id" component={GroupEditPage} />

    <Route exact path="/modules/partners" component={PartnersListPage} />
    <Route exact path="/modules/partners/add" component={PartnersAddPage} />
    <Route exact path="/modules/partners/:id" component={PartnersEditPage} />

    <Route
      exact
      path="/articles/categories"
      component={ArticleCategoriesListPage}
    />
    <Route
      exact
      path="/articles/categories/add"
      component={ArticleCategoriesAddPage}
    />
    <Route
      exact
      path="/articles/categories/:id"
      component={ArticleCategoriesEditPage}
    />

    <Route exact path="/articles" component={ArticlesListPage} />
    <Route exact path="/articles/add" component={ArticlesAddPage} />
    <Route exact path="/articles/:id" component={ArticlesEditPage} />
    {/* <Route path="/settings"> */}
    <Route
      exact
      path="/settings/translations/add"
      component={TranslationAddPage}
    />
    <Route
      exact
      path="/settings/translations/view/:id"
      component={TranslationViewPage}
    />
    <Route
      exact
      path="/settings/translations/:id"
      component={TranslationEditPage}
    />
    <Route
      exact
      path="/settings/translations"
      component={TranslationListPage}
    />
    <Route exact path="/settings/languages/add" component={LanguageAddPage} />
    <Route exact path="/settings/languages/:id" component={LanguageEditPage} />
    <Route exact path="/settings/languages" component={LanguageListPage} />
    <Route exact path="/settings/email" component={EmailPage} />
    <Route
      exact
      path="/settings/milestoneExpireEmail"
      component={MilestoneExpireEmailPage}
    />
    <Route exact path="/settings/shareEmail" component={ShareEmailPage} />
    <Route exact path="/settings/shareMessage" component={ShareMessagePage} />
    <Route
      exact
      path="/settings/shareConceptEmailPage"
      component={ShareConceptEmailPage}
    />
    <Route
      exact
      path="/settings/downloadReminderEmail"
      component={DownloadReminderEmailPage}
    />
    <Route
      exact
      path="/settings/draftReminderEmail"
      component={DraftReminderEmailPage}
    />
    <Route exact path="/settings/smtpSetting" component={SmtpSettingPage} />
    <Route
      exact
      path="/settings/searchPlaceholder"
      component={SearchPlaceholderPage}
    />
    <Route exact path="/settings/contact" component={ContactSettingPage} />
    <Route
      exact
      path="/settings/maintenance"
      component={MaintenanceSettingPage}
    />
    <Route
      exact
      path="/settings/ipAddresses/add"
      component={IpAddressesAddPage}
    />
    <Route
      exact
      path="/settings/ipAddresses/:id"
      component={IpAddressesEditPage}
    />
    <Route exact path="/settings/ipAddresses" component={IpAddressesListPage} />
    <Route exact path="/settings/cookieMessage" component={CookieMessagePage} />
    <Route
      exact
      path="/settings/userWelcomeEmail"
      component={UserWelcomeEmailPage}
    />
    <Route
      exact
      path="/settings/userWelcomeAdminEmail"
      component={UserWelcomeAdminEmailPage}
    />
    <Route
      exact
      path="/settings/forgotPasswordEmail"
      component={ForgotPasswordEmailPage}
    />
    <Route exact path="/settings/templateNotes" component={TemplateNotes} />
    {/* </Route> */}

    <Route exact path="/users/blockedUsers" component={BlockedUsersListPage} />
    <Route
      exact
      path="/users/blockedUsers/:id"
      component={BlockedUsersEditPage}
    />

    <Route exact path="/users" component={UserListPage} />
    <Route exact path="/users/add" component={UserAddPage} />

    {/* <Route path="/users/:id"> */}
    <Route exact path="/users/:id/profile" component={UserEditPage} />
    <Route exact path="/users/:id/drafts" component={UserDraftPage} />
    <Route exact path="/users/:id/workflow" component={UserWorkflowPage} />
    <Route exact path="/users/:id/roledrafts" component={UserRoleDraftPage} />
    <Route
      exact
      path="/users/:id/shareddrafts"
      component={UserSharedDraftPage}
    />
    <Route exact path="/users/:id/downloads" component={UserDownloadPage} />
    {/* <Route path="/messages"> */}
    <Route
      exact
      path="/users/:id/messages/shareemail"
      component={UserShareEmailPage}
    />
    <Route
      exact
      path="/users/:id/messages/sharedraft"
      component={UserShareDraftInstructionPage}
    />
    <Route exact path="/users/:id/messages/alerts" component={UserAlertPage} />
    {/* </Route> */}
    <Route
      exact
      path="/users/:id/settings"
      component={UserRequiredOptionPage}
    />
    {/* </Route> */}
    <Redirect from="*" to="/" />
  </Switch>
);
