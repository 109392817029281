import React, { Component } from "react";
import autoBind from "react-autobind";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";
import _ from "lodash";
import FadeModal from "reboron/FadeModal";
import DropModal from "reboron/DropModal";
import PropTypes from "prop-types";
import { NotificationManager } from "react-notifications";

import Editor from "../Editor";

import ShowMoreText from "react-show-more-text";

import "./GlossaryFormCodeBlocks.scss";
import Helpbox from "../document/Helpbox";
import { viewModals } from "../../helpers";
import api from "../../services/api";
import strings from "../../services/strings";
import settings from "../../services/settings";

let componentInstance = null;

const SortableDragger = SortableHandle(() => {
  return <div className="fieldOptionsDrag" />;
});

const SortableItem = SortableElement(
  ({ value, handleFieldSize, fieldsSize }) => {
    let fieldName = value.name;
    if (value.placeholderField && value.placeholderField !== "") {
      fieldName = value.placeholderField;
    }
    if (
      value.placeholderField === "" &&
      value.placeholder &&
      value.placeholder !== ""
    ) {
      fieldName = value.placeholder;
    }
    let fieldWidth =
      fieldsSize[value.name] !== undefined ? fieldsSize[value.name].size : 100;
    // console.log("fieldWidth", fieldWidth, typeof fieldWidth, fieldsSize[value.name], value.name, fieldsSize)
    return (
      <div
        style={{
          padding: "2px",
          flex: `0 0 ${
            parseInt(fieldWidth)
              ? parseInt(fieldWidth) > 20
                ? parseInt(fieldWidth)
                : 20
              : 20
          }%`,
          zIndex: "1100",
          minWidth: "0px",
        }}
      >
        <div className="ActiveLabel activeFields">
          <div
            className="label-content fieldsContent"
            style={{ display: "flex" }}
          >
            <SortableDragger />
            <span />
            {fieldName}
          </div>
          <div className="fieldSizeContent">
            <input
              type="number"
              key={value.name}
              className="fieldSize"
              value={fieldWidth}
              onChange={(e) => {
                handleFieldSize(e.target.value, value);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
);

const SortableList = SortableContainer(
  ({ items, handleFieldSize, fieldsSize }) => {
    let newItems = [];
    _.map(items, (value, ind) => {
      newItems.push(_.extend({ newIndex: ind }, value));
    });
    let rows = _.map(newItems, (value) => {
      if (isNaN(value.index)) {
        value.index = parseInt(value.index);
      }
      return (
        <SortableItem
          key={`item-${value.newIndex}`}
          index={value.newIndex}
          value={value}
          handleFieldSize={handleFieldSize}
          fieldsSize={fieldsSize}
        />
      );
    });
    return <div className="fieldWrap">{rows}</div>;
  }
);

const SortableTableRow = SortableElement(({ value, prefix = "" }) => {
  let description =
    _.isString(value.description) && value.description
      ? value.description
      : value.description.default
      ? value.description.default
      : "";
  // let name = value.name.substr(value.name.indexOf("_")+1);
  let name = value.name;

  return (
    <tr>
      <td>
        <SortableDragger />
      </td>
      <td>code.block_{value.id}</td>
      <td>{prefix === "" ? value.name : `${prefix}.${name}`}</td>
      <td>
        <ShowMoreText
          lines={2}
          more={strings.get("App.glossaries.glossaryForm.showMore")}
          less={strings.get("App.glossaries.glossaryForm.showLess")}
          anchorClass=""
        >
          {componentInstance.buildLabel(description)}
        </ShowMoreText>
      </td>
      {/* <td>
        <ShowMoreText
          lines={2}
          more={strings.get("App.glossaries.glossaryForm.showMore")}
          less={strings.get("App.glossaries.glossaryForm.showLess")}
          anchorClass=""
        >
          <span dangerouslySetInnerHTML={{ __html: value.helperText }} />
        </ShowMoreText>
      </td>
      <td>{value.helperLink}</td> */}
      <td>
        <i
          className="btn btn-default edit-btn ion-edit"
          onClick={() => componentInstance.handleItemClick(value.index)}
        />
        <i
          className="btn btn-default delete-btn ion-trash-b"
          onClick={() =>
            componentInstance.handleItemRemove(value.index, value.id)
          }
        />
        <i
          className="btn btn-default ion-eye"
          onClick={() => componentInstance.handleItemDocumentList(value.id)}
        />
        <i
          className="btn btn-default ion-android-list"
          onClick={() => componentInstance.handleCodeOrderList(value.id)}
        />
      </td>
    </tr>
  );
});

const SortableTable = SortableContainer(({ items, prefix }) => {
  let rows = _.map(items, (value) => {
    return (
      <SortableTableRow
        key={`item-${value.index}`}
        index={value.index}
        value={value}
        prefix={prefix}
      />
    );
  });

  return (
    <table className="table table-bordered table-list">
      <thead>
        <tr>
          <th>{strings.get("App.glossaries.glossaryForm.order")}</th>
          <th>Id</th>
          <th>Code Blocks</th>
          <th>
            {strings.get(
              "App.glossaries.glossaryForm.clauses.formModal.description"
            )}
          </th>
          <th>{strings.get("General.action")}</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
});

class StepTabs extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      orderedItem: null,
    };
  }

  componentDidMount() {
    if (this.props.items) {
      this.setState({
        orderedItem: this.props.items,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      orderedItem: nextProps.items,
    });
  }

  render() {
    let { orderedItem } = this.state;
    if (!orderedItem) {
      return null;
    }
    const StepList = ({ items }) => {
      let rows = _.map(items, (value) => {
        let className =
          value.id === this.props.currentItemId
            ? "tabItem step-label-content active"
            : "tabItem step-label-content";
        return (
          <div className="ActiveLabel" key={_.uniqueId()}>
            <div
              className={className}
              onClick={() => {
                this.props.onClick(value.id);
              }}
            >
              <span />
              {this.props.buildLabel(value.name)}
            </div>
          </div>
        );
      });
      return <div style={{ width: "100%" }}>{rows}</div>;
    };

    return <StepList items={orderedItem} />;
  }
}

class GlossaryFormCodeBlocks extends Component {
  state = {
    itemsLoaded: false,
    currentItemKey: -1,
    currentItem: null,
    errors: {},
    tmpItem: {
      name: "",
      description: "",
      blockContent: "",
    },
    items: [],
    documentsByCode: [],
    code: "",
    deleteError: false,
    blockContentError: "",
    currentStepId: null,
    fieldsLoaded: false,
    fields: [],
    fieldsSize: {},
    fieldsPercentageStatus: {
      minPer: 20,
      error: false,
    },
    stepsLoaded: false,
    steps: [],
    fieldSuggest: [],
    codeSuggest: [],
  };

  initFormData = {};

  componentDidMount() {
    this.tryLoadItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.prefix !== this.props.prefix) {
      let { tmpItem } = this.state;
      tmpItem.name = nextProps.prefix;
      this.setState({
        tmpItem,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.tryLoadItems();
    if (
      _.size(this.props.documentsByCode) > 0 &&
      _.size(this.props.documentsByCode) !== _.size(prevProps.documentsByCode)
    ) {
      if (!this.state.documentsByCodeLoaded) {
        let documentsByCode = [];
        _.forEach(this.props.documentsByCode, function (value, key) {
          documentsByCode.push(value);
        });
        this.setState({
          documentsByCodeLoaded: true,
          documentsByCode: documentsByCode,
        });
      }
    }

    //Form validation scroll concept.
    if (_.size(this.state.errors)) {
      let el = document.getElementsByClassName("has-error");
      if (_.size(el)) {
        el[0].scrollIntoView({ behavior: "smooth" });
      }
    }
    //check for active modal
    if (this.props.currentModal === viewModals.CODEBLOCK_CODES_ORDERING) {
      this.showCodeOrderListModal();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.size(nextProps.steps) && !this.state.visibleStepsLoading) {
      this.getCurrentSteps(nextProps);
    }
    if(!_.isEqual(_.sortBy(nextProps.items), _.sortBy(this.state.items)) || 
    !_.isEqual(_.sortBy(nextProps.activeItems), _.sortBy(this.state.activeItems))) {

      this.setState({
        items: nextProps.items,
        activeItems: nextProps.activeItems
      });
      if(this.props.activeItems.id) {
        const activeIndex = _.findIndex(this.props.items,(val)=>val.id == this.props.activeItems.id);
        this.handleItemClick(activeIndex);
      }
    }

    if (
      _.size({
        ...nextProps.fields,
        ...nextProps.clauses,
        ...nextProps.selectors,
      }) !== _.size(this.state.fields)
    ) {
      this.loadFields(nextProps);
    }

    if (
      this.state.stepsLoaded &&
      nextProps.stepsFromValidation !== this.state.steps
    ) {
      this.loadSteps(nextProps);
    }
  }
  constructor(props) {
    super(props);
    autoBind(this);
    componentInstance = this;
  }

  tryLoadItems() {
    if (_.size(this.props.items) && !this.state.itemsLoaded) {
      this.setState({
        itemsLoaded: true,
        items: this.props.items,
      });
      if(this.props.activeItems.id) { 
        const activeIndex = _.findIndex(this.props.items,(val)=>val.id == this.props.activeItems.id);
        this.handleItemClick(activeIndex);
      }
    } else if (
      !this.state.tmpItem.name &&
      this.props.currentItem &&
      this.props.currentItem.type === "codeBlocks"
    ) {
      let tmpItem = _.extend({}, this.state.tmpItem);
      tmpItem.name = this.props.currentItem.name;

      this.setState({
        tmpItem,
      });
    } else if (
      _.size(this.state.items) === 0 &&
      this.props.items &&
      _.size(this.props.items) &&
      this.state.itemsLoaded
    ) {
      this.setState({ itemsLoaded: false, items: this.props.items });
    }
    // if ( ((_.size(this.props.fields) || _.size(this.props.selectors) || _.size(this.props.clauses))
    //   && !this.state.fieldsLoaded)) {
    //   this.loadFields(this.props);
    // }

    if (_.size(this.props.stepsFromValidation) && !this.state.stepsLoaded) {
      this.loadSteps(this.props);
    }
  }

  loadFields(props = this.props) {
    let newFields = _.map(props.fields, (item) => {
      return item.asMutable ? item.asMutable() : item;
    });
    _.map(props.selectors, (item) => {
      newFields.push(item.asMutable ? item.asMutable() : item);
    });
    _.map(props.clauses, (item) => {
      newFields.push(item.asMutable ? item.asMutable() : item);
    });
    if (
      this.state.currentItem &&
      this.state.currentItem.contentCodeOrder &&
      this.state.currentItem.contentCodeOrder !== "" &&
      this.state.currentItem.contentCodeOrder !== "null"
    ) {
      let codeOrders;
      try {
        codeOrders = JSON.parse(this.state.currentItem.contentCodeOrder);
      } catch (error) {
        console.log(error);
      }
      _.map(newFields, (item) => {
        if (typeof codeOrders[item.name] !== "undefined") {
          item.index = codeOrders[item.name].order;
        }
      });
      newFields = _.sortBy(newFields, "index");
    }
    newFields = this.orderFieldsByStep(newFields);
    this.setState({
      fieldsLoaded: true,
      fields: newFields,
    });
  }

  loadSteps = (props = this.props) => {
    if (_.size(props.stepsFromValidation) === 0 && this.state.stepsLoaded) {
      this.setState({
        stepsLoaded: false,
      });
    }
    let items = _.map(props.stepsFromValidation, (step) => {
      return step;
    });
    let current_step_id = 0;
    if (items.length && items[0].id) {
      current_step_id = items[0].id;
    }
    this.setState({
      stepsLoaded: true,
      steps: items,
      currentStepId: current_step_id,
    });
  };

  orderFieldsByStep(arr) {
    let y = [];
    _.map(arr, (field) => {
      if (!y[field["stepId"] - 1]) y[field["stepId"] - 1] = [];
      y[field["stepId"] - 1].push(field);
    });

    let newArr = [];
    y = _.map(y, (item) => {
      if (typeof item !== "undefined") newArr.push(item);
    });

    return newArr;
  }

  validate() {
    let item = this.state.tmpItem;
    let duplicate = this.isDuplicated(item);
    let errors = {};

    if (item.name === "") {
      errors["name"] = strings.get("Exceptions.required");
    } else if (!/^[a-z0-9_]+$/.test(item.name)) {
      errors["name"] = strings.get("Exceptions.onlyLowercase");
    }

    if (!_.isObject(item.description)) {
      errors["description"] = strings.get("Exceptions.required");
    } else if (
      item.description.length >= 1 &&
      item.description.default &&
      item.description.default === ""
    ) {
      errors["description"] = strings.get("Exceptions.required");
    }
    // if (item.stepId === -1) {
    //   errors["stepId"] = strings.get("Exceptions.required");
    // }

    if (duplicate) {
      if (_.isObject(duplicate)) {
        errors["name"] = strings.get("Exceptions.existsInOther", {
          itemName: duplicate.name,
        });
      } else {
        errors["name"] = strings.get("Exceptions.existsInThis");
      }
    }

    if (_.size(errors)) {
      this.setState({ errors });
      return false;
    }

    return true;
  }

  isDuplicated(item) {
    let duplicate;
    let currentGlossary = this.props.currentGlossary;

    // If item has been edited and name didn't change is not duplicate
    if (this.state.currentItem && item.name === this.state.currentItem.name) {
      return null;
    }

    // Check duplicates in other glossaries
    _.each(this.props.glossaries, (glossary) => {
      // Skip current glossary
      if (!currentGlossary || currentGlossary.id !== glossary.id) {
        let value = JSON.parse(glossary.value);

        _.each(value.clauses, (clause) => {
          if (clause.name === item.name) {
            duplicate = glossary;
          }
        });
      }
    });

    // Check duplicates into current glossary
    _.each(this.state.items, (clause) => {
      if (clause.name === item.name) {
        duplicate = true;
      }
    });

    return duplicate;
  }

  listClauses() {
    let currentGlossary = this.props.currentGlossary;

    let clauses = [];

    _.each(this.props.glossaries, (glossary) => {
      // Skip current glossary
      if (
        (!currentGlossary || currentGlossary.id !== glossary.id) &&
        glossary.value
      ) {
        let value = JSON.parse(glossary.value);

        _.each(value.clauses, (clause) => {
          clauses.push({
            value: clause.name,
            label: clause.name,
          });
        });
      }
    });

    return clauses;
  }

  hasError(inputName) {
    return !!this.state.errors[inputName];
  }

  getErrorClass(inputName, defaultClasses = "") {
    return this.hasError(inputName)
      ? defaultClasses + " has-error"
      : defaultClasses;
  }

  getErrorMessage(inputName) {
    return this.state.errors[inputName];
  }


  getSuggesions = () =>{
    let codeSuggest = [];
    let fieldSuggest = [];
    const  currentGlossary = this.props.currentGlossary;
    const glossariesValue =currentGlossary? JSON.parse(currentGlossary.value):{};
    _.map(glossariesValue, (elements,key) => {
      let name = "";
      if(key === "carousels") {
         name = `carousel.carousel_`;
      }
      else if(key === "clauses") {
          name = `if.clause_`;
      }
      else if(key === "selectors") {
        name = `select.select_`;
      }
      else if(key === "CodeBlocks") {
        name = `code.block_`;
      }
      else {
        name = "field_";
      }

      _.forEach(elements, (code) => {
          if(key === "fields") {
            fieldSuggest.push({name: `${name}${code.id}`, contentName: code.name});
          }
          else {
            codeSuggest.push({name: `${name}${code.id}`, contentName: code.name});
          }
      });
    });
    this.setState({
      codeSuggest,
      fieldSuggest
    });
  }

  showFormModal() {
    this.getSuggesions()
    this.refs.formModal.show();
  }

  showDeleteModal() {
    this.refs.deleteModal.show();
  }

  hideFormModal() {
    this.refs.formModal.hide();
    if(this.props.activeItems.id)  window.close();
  }

  hideDeleteModal() {
    this.refs.deleteModal.hide();
  }

  processItems(item) {
    let description = _.isObject(item.description)
      ? item.description
      : {
          default: item.description,
        };

    return _.extend(item, {
      description,
    });
  }

  loadAllDocuments(code) {
    if (code !== this.state.code) {
      this.setState({
        documentsByCodeLoaded: false,
        documentsByCode: [],
      });
    }
    this.props.setGlossaryCode(code, "codeBlock");
    this.props.fetchAllDocuments(true);
  }

  handleItemClick(key) {
    // this.loadAllDocuments(code);
    this.initFormData = JSON.stringify(
      this.processItems(this.state.items[key]||{})
    );
    this.showFormModal();
    this.setState({
      currentItemKey: key,
      currentItem: JSON.parse(this.initFormData),
      tmpItem: JSON.parse(this.initFormData),
    });
  }

  handleItemRemove(key, code) {
    // let SelectedItem = _.find(this.state.items, function(o) { return o.index === key; });
    this.loadAllDocuments(code);
    _.delay(() => {
      this.showDeleteModal();
    }, 500);
    this.setState({
      currentItemKey: key,
      // currentItem: _.extend({}, SelectedItem),
    });
  }

  handleItemDocumentList(code) {
    this.loadAllDocuments(code);
    this.setState({
      code: code,
    });
    _.delay(() => {
      this.showDocumentListModal();
    }, 500);
  }

  showDocumentListModal() {
    this.refs.documentListModal.show();
  }

  hideDocumentListModal() {
    this.refs.documentListModal.hide();
  }

  // hideBlockContentInfoModal() {
  //   this.refs.blockContentInfo.hide();
  // }

  // showBlockContentInfoModal() {
  //   this.refs.blockContentInfo.show();
  // }

  handleCodeOrderList(codeBlockId) {
    let { items, fieldsSize } = this.state;
    let currentCodeBlock = {};
    items.forEach((codeBlock) => {
      if (codeBlock.id === codeBlockId) {
        currentCodeBlock = codeBlock;
        if (
          codeBlock.contentCodeOrder &&
          codeBlock.contentCodeOrder !== "" &&
          codeBlock.contentCodeOrder !== "null"
        ) {
          try {
            fieldsSize = JSON.parse(codeBlock.contentCodeOrder);
          } catch (error) {
            console.trace(error);
            fieldsSize = {};
          }
        } else {
          fieldsSize = {};
        }
      }
    });
    this.setState(
      {
        fields: [],
        steps: [],
        fieldsLoaded: false,
        stepsLoaded: false,
        fieldsSize,
      },
      () => {
        if (_.size(currentCodeBlock)) {
          this.props.validateTemplate(currentCodeBlock);
          this.setState({ currentItem: currentCodeBlock });
        }
        // this.showCodeOrderListModal();
      }
    );
  }

  showCodeOrderListModal() {
    if (this.refs.CODEBLOCK_CODES_ORDERING)
      this.refs.CODEBLOCK_CODES_ORDERING.show();
  }

  hideCodeOrderListModal() {
    this.props.setCurrentModal("");
    if (this.refs.CODEBLOCK_CODES_ORDERING)
      this.refs.CODEBLOCK_CODES_ORDERING.hide();
  }

  onHideCodeOrderListModal() {
    this.setState({ currentItem: undefined });
    this.props.setCurrentModal("");
  }

  documentsList() {
    let items = _.map(this.state.documentsByCode, (item) => {
      return (
        <tr key={item.id}>
          <td>
            <a href={`/documents/${item.id}`} target="_blank">
              {item.name}
            </a>
          </td>
          <td>{item.category ? item.category.name : ""}</td>
        </tr>
      );
    });
    return items;
  }

  handleConfirmItemRemove(e) {
    e.preventDefault();
    if (this.state.documentsByCode.length) {
      this.setState({
        deleteError: true,
      });
    } else {
      let index = this.state.currentItemKey;
      let code_block_id = this.state.items[index].id;
      if (this.props.currentGlossary) {
        this.props.onDeleteCodeBlock(code_block_id);
      }
      let items = _.filter(this.state.items, (item, key) => {
        return key !== this.state.currentItemKey;
      });

      this.props.onChange(items);
      this.hideDeleteModal();
      _.delay(
        () =>
          this.setState({
            currentItemKey: -1,
            items,
          }),
        250
      );
    }
  }

  handleAddClick() {
    if (this.props.currentItem) this.props.addClick();

    this.showFormModal();
  }

  handleSortEnd({ oldIndex, newIndex }) {
    let items = arrayMove(this.state.items, oldIndex, newIndex);
    items.forEach((item, index) => {
      item.codeOrder = index;
    });
    this.props.onChange(items);
    this.setState({ items });
  }

  handleInputChange(e) {
    let item = { ...this.state.tmpItem };
    if (e.target.getAttribute("data-key")) {
      item[e.target.name] = {
        ...item[e.target.name],
        [e.target.getAttribute("data-key")]: e.target.value,
      };
    } else {
      // if (e.target.name === "name" && this.state.documentsByCode.length > 0) {
      //   let {errors} = this.state
      //   errors["name"] = strings.get("App.glossaries.glossaryForm.renameError");
      //   this.setState({errors});
      //   _.delay(() => {
      //     this.setState({errors : {}})
      //   }, 2000);
      // }
      // else{
      item[e.target.name] = e.target.value;
      // }
    }
    this.setState({
      tmpItem: _.extend(this.state.tmpItem, item),
    });
  }

  handleEditorChange(e) {
    this.setState({
      tmpItem: _.extend(this.state.tmpItem, {
        helperText: e.target.getContent(),
      }),
    });
  }

  handleEditorChangeBlockContent(e) {
    this.setState({
      tmpItem: _.extend(this.state.tmpItem, {
        blockContent: e.target.getContent(),
      }),
    });
  }

  handleChangeToRegex(contentTemplate, itemKey, isRegex) {
    let tempRegex = null;
    while (null !== (tempRegex = isRegex.exec(contentTemplate))) {
      if (tempRegex) {
        if (itemKey&&
          itemKey[tempRegex[1]] &&
          itemKey[tempRegex[1]].id
        ) {
          if (
            isRegex.toString() === new RegExp(/.*\[select_(.*)\]/g).toString()
          ) {
            contentTemplate = contentTemplate.replace(
              `_${tempRegex[1]}`,
              `.select_${itemKey[tempRegex[1]].id}`
            );
          } else {
            contentTemplate = contentTemplate.replace(
              `_${tempRegex[1]}`,
              `_${itemKey[tempRegex[1]].id}`
            );
          }
        }
      }
    }
    return contentTemplate;
  }

  onScroll = (id) => {
    var myElement = document.getElementById(id);
    if (myElement) {
      document
        .getElementById("helpbox-container")
        .querySelectorAll(".active")
        .forEach((e) => e.classList.remove("active"));
      myElement.classList.add("active");
      var topPos = myElement.offsetTop;
      document.getElementById("helpbox-container").scrollTop = topPos - 726;
    }
  };

  handleTemplateChange(e) {
    let contentTemplate = e; //.target.getContent();
    let {
      itemKeySelectors,
      itemKeyClauses,
      itemKeyFields,
      itemKeyCodeBlocks,
      itemKeyCarousels,
    } = this.props.itemElements;
    let isSelector = new RegExp(/.*\[select_(.*)\]/g);
    let isClause = new RegExp(/.*\[if.clause_(.*)\]/g);
    let isField = new RegExp(/.*\[\[field_(.*)\]\]/g);
    let iscodeBlock = new RegExp(/.*\[code.block_(.*)\]/g);
    let isCarousel = new RegExp(/.*\[carousel_(.*)\]/g);

    contentTemplate = this.handleChangeToRegex(
      contentTemplate,
      itemKeySelectors,
      isSelector
    );
    contentTemplate = this.handleChangeToRegex(
      contentTemplate,
      itemKeyFields,
      isField
    );
    contentTemplate = this.handleChangeToRegex(
      contentTemplate,
      itemKeyClauses,
      isClause
    );
    contentTemplate = this.handleChangeToRegex(
      contentTemplate,
      itemKeyCodeBlocks,
      iscodeBlock
    );
    contentTemplate = this.handleChangeToRegex(
      contentTemplate,
      itemKeyCarousels,
      isCarousel
    );

    this.setState({
      tmpItem: _.extend(this.state.tmpItem, {
        blockContent: contentTemplate,
      }),
    });
  }

  handleRequiredChange(e) {
    let item = {};
    item[e.target.name] = e.target.value;
    this.setState({
      tmpItem: _.extend(this.state.tmpItem, item),
    });
  }

  handleFormModalHide() {
    this.setState({
      itemsLoaded: false,
      currentItemKey: -1,
      currentItem: null,
      errors: {},
      tmpItem: {
        // stepId: -1,
        name: this.props.prefix,
        description: "",
        // helperText: "",
        // helperLink: "",
      },
      items: [],

      code: "",
      documentsByCode: [],
    });
    if(this.props.activeItems.id)  window.close();
  }

  getNameCount = (name) =>{
    let overallCount,currentCount
    let currentGlossary = this.props.currentGlossary;
    _.each(this.props.glossaries, (glossary) => {
      // Skip current glossary
      if (!currentGlossary || currentGlossary.id !== glossary.id) {
        let value = JSON.parse(glossary.value);

        overallCount =  _.filter(value.code_blocks, (code_block) => 
          code_block.name.search(name) !== -1
        );
      }
    });
    currentCount =  _.filter(this.state.items, (code_block) => 
      code_block.name.search(name) !== -1 
    );
    return currentCount.length +  overallCount.length + 1;
  }

  getCopyName = (item) =>{
    if (item.name.search('_copy_') === -1) {
      const count = this.getNameCount(`${item.name}_copy`)
      return `${item.name}_copy_${count}`
    }

    const splitval = item.name.split('_');
    splitval.splice(splitval.length - 1, 1);
    const count = this.getNameCount(splitval.join('_'));
  
    return  `${splitval.join('_')}_${count}`
  }

  async handleSaveClick(e, isCopy) {
    e.preventDefault();
    // Validate temporary item
    if (this.checkBlockContentForRules()){
    if (this.validate() || (this.state.currentItemKey !== -1 && isCopy )) {
      let items = _.extend([], this.state.items);
      let tmpItem = this.state.tmpItem;
      if (this.state.currentItemKey === -1) {
        // Add item
        tmpItem.id = `${_.uniqueId("temp_")}`;
        items.push(tmpItem);
        // let data = tmpItem;
        // data.glossary_id = this.props.currentGlossary.id;
        // let resData = await this.props.createCodeBlock({
        //   ...data,
        //   description: JSON.stringify(data.description)
        // });
        // items.push(resData);
      } else {
        // Edit item
        if (!isNaN(tmpItem.id)) {
          let code_block_id = this.state.currentItem.id;
          let data = tmpItem;
          delete data.index;
          delete data.created_at;
          delete data.deleted_at;
          delete data.updated_at;
          data.copy = !!isCopy;
          if (isCopy) {
              data.copy_name = this.getCopyName(tmpItem);
          }
          await this.props.onSaveCodeBlock(code_block_id, {
            ...data,
            description: JSON.stringify(data.description),
          },!!this.props.activeItems.id);
        }
        items[this.state.currentItemKey] = tmpItem;
      }
      if (
        this.props.exceptions.template &&
        this.props.exceptions.template.notDefined
      ) {
        this.setState({
          blockContentError: "Template contains rules not added in glossary.",
        });
      } else {
        //not exceptions then show errors
        this.props.onChange(items);
        if (!this.props.activeItems.id) this.hideFormModal();
      }
    } else {
      NotificationManager.info(
        strings.get("Exceptions.notifications.requestNotAccepted")
      );
    }
  }
  else {
    NotificationManager.info(
      strings.get("Exceptions.notifications.requestNotAccepted")
    );
  }
  }
  handleVerifyCodeblock = async (e) => {
    e.preventDefault();
    let obj = {};
    obj.blockContent =
      this.state.tmpItem && this.state.tmpItem.blockContent
        ? this.state.tmpItem.blockContent
        : "";
     const response = await this.props.validateGlossaryTemplate(obj);

    // if (response) {
    //   NotificationManager.success(
    //     strings.get("Exceptions.notifications.validTemplate")
    //   );
    // } else {
    //   NotificationManager.error(
    //     strings.get("App.documents.documentForm.invalidTemplateMessage")
    //   );
    // }
  };

  checkBlockContentForRules = async() => {
    // Currently the only rule is we can not use nested codeBlocks
    let valid = true;
    try {
      this.props.setLoadingScreen(true);
      let obj = {};
      obj.blockContent =
        this.state.tmpItem && this.state.tmpItem.blockContent
          ? this.state.tmpItem.blockContent
          : "";
      //Now we are only checking direct nested codeblocks
      let iscodeBlock = new RegExp(/.*\[code.block_(.*)\]/g);
      let result = iscodeBlock.exec(obj.blockContent);
      if (_.size(result)) {
        valid = false;
      }

      // // If we need to calculate direct-indirect codeblocks then uncomment this
      // let params = new Map();
      // _.map(obj, (value, key) => {
      //   params.set(key, value);
      // });
      // // POST request to API
      // let response = await api.post(`/documents/validateTemplate`, params);
      // if(response && response.codeBlocks && _.size(response.codeBlocks)){
      //   valid = false
      // }
      const response = await this.props.validateGlossaryTemplate(obj);
      console.log(response)
    } catch (error) {
      valid = false;
    } finally {
      this.props.setLoadingScreen(false);
      return valid;
    }
  };

  resetFormModal = () => {
    this.setState({
      currentItem: JSON.parse(this.initFormData),
      tmpItem: JSON.parse(this.initFormData),
      blockContentError: "",
    });
  };

  handleCancelClick = (e) => {
    e.preventDefault();

    // this.resetFormModal();  *uncaught error-Unexpected token o in JSON at position 1*
    this.hideFormModal();
    this.hideDeleteModal();
  };

  hanldeDeleteModalHide() {
    this.setState({
      deleteError: false,
      code: "",
    });
  }

  handleAccordionToggle() {
    if (this.props.currentItem)
      this.refs.hidden.style.display =
        this.refs.hidden.style.display === "none" ? "" : "none";
  }

  buildLabel(Item, item) {
    if (!Item || Item === undefined) return Item;
    // let _glossaries = this.props.glossaries;
    let glossariesCodeIdToName = this.props.glossariesCodeIdToName;
    let expandGlossaries = this.props.expandGlossaries;
    let _label = Item;
    // separate out all selectors and loop through
    let result = strings.getFromBetween.get(_label, "[[", "]]");

    result.forEach((e) => {
      const reg = new RegExp(e);

      //if multiple codes are responsible for name of a step
      let stepNameOptions = e.split("||");
      if (stepNameOptions.length > 1) {
        stepNameOptions.forEach((element) => {
          element = element.trim();
          // work out pattern [[*.*_*]] for selects
          if (element.indexOf(".") > -1) {
            const field = element.split(".")[0];
            if (expandGlossaries && _.size(expandGlossaries)) {
              if (
                expandGlossaries[field] &&
                expandGlossaries[field].placeholderField
              ) {
                _label = _label
                  .replace(element, expandGlossaries[field].placeholderField)
                  .replace(/\[\[|\]\]/g, "");
              } else if (
                expandGlossaries[glossariesCodeIdToName[field]] &&
                expandGlossaries[glossariesCodeIdToName[field]].placeholderField
              ) {
                _label = _label
                  .replace(
                    element,
                    expandGlossaries[glossariesCodeIdToName[field]]
                      .placeholderField
                  )
                  .replace(/\[\[|\]\]/g, "");
              } else {
                _label = _label
                  .replace(element, "< ## >")
                  .replace(/\[\[|\]\]/g, "");
              }
            }
          } else {
            if (expandGlossaries && _.size(expandGlossaries)) {
              if (
                expandGlossaries[element] &&
                expandGlossaries[element].placeholderField
              ) {
                _label = _label
                  .replace(element, expandGlossaries[element].placeholderField)
                  .replace(/\[\[|\]\]/g, "");
              } else if (
                expandGlossaries[glossariesCodeIdToName[element]] &&
                expandGlossaries[glossariesCodeIdToName[element]]
                  .placeholderField
              ) {
                _label = _label
                  .replace(
                    element,
                    expandGlossaries[glossariesCodeIdToName[element]]
                      .placeholderField
                  )
                  .replace(/\[\[|\]\]/g, "");
              } else {
                _label = _label
                  .replace(element, "< ## >")
                  .replace(/\[\[|\]\]/g, "");
              }
            }
          }
        });
      }
      //if single code is responsible for name of a step
      // work out pattern [[*.*_*]] for selects
      else if (e.indexOf(".") > -1) {
        const field = e.split(".")[0];
        if (expandGlossaries && _.size(expandGlossaries)) {
          if (
            expandGlossaries[field] &&
            expandGlossaries[field].placeholderField
          ) {
            _label = _label
              .replace(reg, expandGlossaries[field].placeholderField)
              .replace(/\[\[|\]\]/g, "");
          } else if (
            expandGlossaries[glossariesCodeIdToName[field]] &&
            expandGlossaries[glossariesCodeIdToName[field]].placeholderField
          ) {
            _label = _label
              .replace(
                reg,
                expandGlossaries[glossariesCodeIdToName[field]].placeholderField
              )
              .replace(/\[\[|\]\]/g, "");
          } else {
            _label = _label.replace(reg, "< ## >").replace(/\[\[|\]\]/g, "");
          }
        }
      } else {
        // if there's no placeholder there, then find placeholder in all glossaries
        if (expandGlossaries && _.size(expandGlossaries)) {
          if (expandGlossaries[e] && expandGlossaries[e].placeholderField) {
            _label = _label
              .replace(reg, expandGlossaries[e].placeholderField)
              .replace(/\[\[|\]\]/g, "");
          } else if (
            expandGlossaries[glossariesCodeIdToName[e]] &&
            expandGlossaries[glossariesCodeIdToName[e]].placeholderField
          ) {
            _label = _label
              .replace(
                reg,
                expandGlossaries[glossariesCodeIdToName[e]].placeholderField
              )
              .replace(/\[\[|\]\]/g, "");
          } else {
            _label = _label.replace(reg, "< ## >").replace(/\[\[|\]\]/g, "");
          }
        }
      }
    });
    return _label;
  }

  handleArticleChange = (selectedOption) => {
    if (selectedOption) {
      this.setState({
        tmpItem: _.extend(this.state.tmpItem, {
          helperLink: selectedOption.value,
        }),
      });
    } else {
      this.setState({
        tmpItem: _.extend(this.state.tmpItem, {
          helperLink: "",
        }),
      });
    }
  };

  handleTabsItemClick(id) {
    this.setState({ currentStepId: id });
  }

  handleSortEnd({ oldIndex, newIndex, index }) {
    let stepItems = _.find(this.state.fields, ["stepId", index]);
    let stepItemsIndex = _.findIndex(this.state.fields, ["stepId", index]);
    let items = arrayMove(stepItems, oldIndex, newIndex);
    let newItems = [];
    _.map(this.state.fields, (field, ind) => {
      if (ind === stepItemsIndex) {
        newItems[ind] = items;
      } else {
        newItems[ind] = field;
      }
    });
    this.setState({ fields: newItems });
  }

  codeBlockFieldsOrderModalConfirm() {
    let { fieldsSize } = this.state;
    let newFields = {};
    let ind = 0;
    _.map(this.state.fields, (items) => {
      _.map(items, (item) => {
        let temp = {};
        temp.order = ind;
        if (fieldsSize[item.name]) {
          temp.size = parseInt(fieldsSize[item.name].size)
            ? parseInt(fieldsSize[item.name].size) <
              this.state.fieldsPercentageStatus.minPer
              ? this.state.fieldsPercentageStatus.minPer
              : parseInt(fieldsSize[item.name].size)
            : this.state.fieldsPercentageStatus.minPer;
        } else {
          temp.size = 100;
        }
        newFields[item.name] = temp;
        ind++;
      });
    });
    // if(this.state.currentItem && _.size(newFields)){
    if (
      !this.state.fieldsPercentageStatus.error &&
      this.state.currentItem &&
      _.size(newFields)
    ) {
      let { items, currentItem } = this.state;
      items.forEach((item) => {
        if (item.id === this.state.currentItem.id) {
          item.contentCodeOrder = JSON.stringify(newFields);
        }
      });
      currentItem.contentCodeOrder = JSON.stringify(newFields);
      this.setState({ items, currentItem }, async () => {
        if (!isNaN(currentItem.id)) {
          delete currentItem.index;
          delete currentItem.created_at;
          delete currentItem.deleted_at;
          delete currentItem.updated_at;

          await this.props.onSaveCodeBlock(currentItem.id, {
            ...currentItem,
            description: JSON.stringify(currentItem.description),
          },
          !!this.params.activeItems.id);
          this.hideCodeOrderListModal();
        }
      });
    }
  }

  handleFieldSize(data, element) {
    let { fieldsSize, fieldsPercentageStatus } = this.state;
    if (parseInt(data)) {
      if (parseInt(data) <= 100) {
        if (fieldsSize[element.name]) {
          fieldsSize[element.name].size = data;
        } else {
          let x = {};
          x.size = data;
          fieldsSize[element.name] = x;
        }
      }
    } else {
      if (fieldsSize[element.name]) {
        fieldsSize[element.name].size = data;
      } else {
        let x = {};
        x.size = data;
        fieldsSize[element.name] = x;
      }
    }
    if (parseInt(data) && parseInt(data) > fieldsPercentageStatus.minPer) {
      fieldsPercentageStatus.error = false;
    } else {
      fieldsPercentageStatus.error = true;
    }
    this.setState({ fieldsSize, fieldsPercentageStatus });
  }

  getTemplateErrors() {
  console.log('exceptions -- > ',this.props.exceptions)
    if (
      this.props.exceptions.blockContent &&
      this.props.exceptions.blockContent.notDefined
    ) {
      let errors = this.props.exceptions.blockContent.notDefined;

      let wrongFields = "";
      let wrongClauses = "";
      let wrongSelectors = "";
      if (errors) {
        if (errors.fields) {
          wrongFields = _.map(_.uniq(errors.fields), (field, i) => {
            return (
              <span key={`item-${i}`}>
                {field}
                <button
                  onClick={(e) => {
                    this.handleAddRule(e, field, "field");
                  }}
                  className="btn btn-default add-button"
                  id={i}
                >
                  +
                </button>
                <br />
              </span>
            );
          });
        }
        if (errors.clauses) {
          wrongClauses = _.map(_.uniq(errors.clauses), (clause, i) => {
            return (
              <span key={`item-${i}`}>
                {clause}
                <button
                  onClick={(e) => {
                    this.handleAddRule(e, clause, "clause");
                  }}
                  className="btn btn-default add-button"
                  id={i}
                >
                  +
                </button>
                <br />
              </span>
            );
          });
        }
        if (errors.selectors) {
          wrongSelectors = _.map(_.uniq(errors.selectors), (selector, i) => {
            return (
              <span key={`item-${i}`}>
                {selector}
                <button
                  onClick={(e) => {
                    this.handleAddRule(e, selector, "selector");
                  }}
                  className="btn btn-default add-button"
                  id={i}
                >
                  +
                </button>
                <br />
              </span>
            );
          });
        }
      }

      return (
        <div className="alert alert-danger">
          {/* {errors.selectors ? <p>Selectors: {wrongSelectors}</p> : null}
          {errors.clauses ? <p>Clauses: {wrongClauses}</p> : null}
          {errors.fields ? <p>Fields: {wrongFields}</p> : null} */}
          {errors.selectors ? (
            <p>
              {strings.get("App.glossaries.glossaryForm.selectors.title")}:{" "}
              {wrongSelectors}
            </p>
          ) : null}
          {errors.clauses ? (
            <p>
              {strings.get("App.glossaries.glossaryForm.clauses.title")}:{" "}
              {wrongClauses}
            </p>
          ) : null}
          {errors.fields ? (
            <p>
              {strings.get("App.glossaries.glossaryForm.fields.title")}:{" "}
              {wrongFields}
            </p>
          ) : null}
        </div>
      );
    } else if (
      this.props.exceptions.blockContent &&
      this.props.exceptions.blockContent.rulesTree
    ) {
      return (
        <div className="alert alert-danger">
          <p>
            {strings.get("App.documents.documentForm.invalidTemplateMessage")}
          </p>
        </div>
      );
    } else if (
      this.props.exceptions.blockContent &&
      this.props.exceptions.blockContent.codeMismatch
    ) {
      return (
        <div className="alert alert-danger">
          <p>
            {this.props.exceptions.blockContent.type === "open" ?
              `${this.props.exceptions.blockContent.codeMismatch} ${strings.get("Exceptions.open_tag_error")}` :
              (
                this.props.exceptions.blockContent.type === "close" ?
                  `${this.props.exceptions.blockContent.codeMismatch} ${strings.get("Exceptions.close_tag_error")}` :
                  `${strings.get("Exceptions.unknown_tag_error")} ${this.props.exceptions.blockContent.codeMismatch}`
              )
            }
          </p>
        </div>
      );
    }
  }

  render() {
    let { blockContentError } = this.state;

    let descriptionLabel = this.hasError("description")
      ? `${strings.get(
          "App.glossaries.glossaryForm.clauses.formModal.description"
        )} ${this.getErrorMessage("description")}`
      : strings.get(
          "App.glossaries.glossaryForm.clauses.formModal.description"
        );

    let deleteModalContent =
      this.state.currentItemKey !== -1 ? (
        <span>
          <div className="modal-close-btn">
            <i
              className="ion-android-cancel clickable"
              onClick={this.handleCancelClick}
            />
          </div>
          <h2>
            {strings.get("App.deleteModal.message", {
              itemName: this.state.items[this.state.currentItemKey].name,
            })}
          </h2>
          {/*<h2>{ strings.get('App.deleteModal.message', {itemName: this.state.currentItem.name}) }</h2>*/}
          {this.state.deleteError ? (
            <div style={{ color: "red" }}>
              {strings.get("App.deleteModal.error")}
            </div>
          ) : null}
          <div className="form-actions">
            <button
              className="btn btn-lg btn-danger"
              onClick={this.handleConfirmItemRemove}
            >
              {strings.get("App.deleteModal.delete")}
            </button>
            <button
              className="btn btn-lg cancel"
              onClick={this.handleCancelClick}
            >
              {strings.get("App.deleteModal.cancel")}
            </button>
          </div>
        </span>
      ) : null;

    let deleteModal = (
      <DropModal
        className="boron-modal"
        ref="deleteModal"
        onHide={this.hanldeDeleteModalHide}
      >
        {deleteModalContent}
      </DropModal>
    );

    let tooltip = (
      <Tooltip id="block-content-info">
        <div
          dangerouslySetInnerHTML={{
            __html:
              this.props.settings &&
              this.props.settings[settings.keys.CODE_BLOCK_CONTENT_NOTES]
                ? this.props.settings[settings.keys.CODE_BLOCK_CONTENT_NOTES]
                    .value
                : "",
          }}
        />
      </Tooltip>
    );

    // let blockContentInfo = (
    //   <DropModal className="boron-modal" ref="blockContentInfo">
    //     <div className="modal-close-btn">
    //       <i
    //         className="ion-android-cancel clickable"
    //         onClick={this.hideBlockContentInfoModal}
    //       />
    //     </div>
    //     <div
    //       dangerouslySetInnerHTML={{
    //         __html:
    //           this.props.settings &&
    //           this.props.settings[settings.keys.CODE_BLOCK_CONTENT_NOTES]
    //             ? this.props.settings[settings.keys.CODE_BLOCK_CONTENT_NOTES]
    //                 .value
    //             : "",
    //       }}
    //     />
    //   </DropModal>
    // );

    let sortableListsBySteps;
    // if (_.size(this.state.fields)) {
    let stepFields = this.state.fields;
    _.map(stepFields, (fields, ind) => {
      stepFields[ind].stepId = parseInt(fields[0].stepId);
    });
    let listItems = _.find(stepFields, ["stepId", this.state.currentStepId]);
    if (_.size(listItems)) {
      sortableListsBySteps = (
        <div key={`item-${this.state.currentStepId}`}>
          <SortableList
            items={listItems}
            handleFieldSize={this.handleFieldSize}
            fieldsSize={this.state.fieldsSize}
            onSortEnd={({ oldIndex, newIndex }) => {
              this.handleSortEnd({
                oldIndex,
                newIndex,
                index: this.state.currentStepId,
              });
            }}
            axis="xy"
            useDragHandle
            lockToContainerEdges
            helperClass="SortableItem Field"
          />
        </div>
      );
    } else {
      sortableListsBySteps = (
        <div className="noData">
          {strings.get("App.glossaries.glossaryForm.noData")}
        </div>
      );
    }

    let codeOrderModal = (
      <DropModal
        className="boron-modal"
        ref={viewModals.CODEBLOCK_CODES_ORDERING}
        onHide={this.onHideCodeOrderListModal}
      >
        <div>
          <div className="modal-close-btn">
            <i
              className="ion-android-cancel clickable"
              onClick={this.hideCodeOrderListModal}
            />
          </div>
          <label>
            {strings.get("App.documents.documentForm.orderFieldsModal.title")}
          </label>
          <br />
          <StepTabs
            items={this.state.steps}
            currentItemId={this.state.currentStepId}
            fields={this.state.fields}
            onClick={this.handleTabsItemClick}
            // stepsOrder = {this.state.currItem && this.state.currItem.steps_order ? this.state.currItem.steps_order : ''}
            buildLabel={this.buildLabel}
          />
          {sortableListsBySteps}
          {this.state.fieldsPercentageStatus.error ? (
            <div className="fieldSizeError">
              {strings.get(
                "App.documents.documentForm.orderFieldsModal.fieldSizeError"
              )}{" "}
              {this.state.fieldsPercentageStatus.minPer}%
            </div>
          ) : null}
          <button
            style={{ marginRight: ".4rem" }}
            className="btn btn-primary"
            onClick={this.codeBlockFieldsOrderModalConfirm}
          >
            {strings.get("App.documents.documentForm.orderFieldsModal.save")}
          </button>
          <button
            style={{ marginLeft: ".4rem" }}
            className="btn btn-default"
            onClick={this.hideCodeOrderListModal}
          >
            {strings.get("App.documents.documentForm.orderFieldsModal.cancel")}
          </button>
        </div>
      </DropModal>
    );

    let documentListModalContent = (
      <div className="documentsList">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th> {strings.get("App.users.downloads.documentName")} </th>
              <th> {strings.get("App.users.downloads.category")} </th>
            </tr>
          </thead>
          <tbody>{this.documentsList()}</tbody>
        </table>
      </div>
    );

    let formModal = (
      <FadeModal
        className="boron-modal form-modal"
        ref="formModal"
        onHide={this.handleFormModalHide}
      >
        <div className="modal-close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.hideFormModal}
          />
        </div>
        <div className="scrollable">
          <div className={this.getErrorClass("name", "form-group")}>
            <label className="control-label">Code Blocks</label>
            <input
              className="form-control"
              type="text"
              name="name"
              value={this.state.tmpItem.name}
              onChange={this.handleInputChange}
            />
          </div>

          <div className={this.getErrorClass("description", "form-group")}>
            <label className="control-label">{descriptionLabel}</label>
            {["default", "me", "client"].map((key) => (
              <textarea
                key={key}
                className="form-control"
                name="description"
                data-key={key}
                value={this.state.tmpItem.description[key]}
                onChange={this.handleInputChange}
                placeholder={key}
              />
            ))}
          </div>
          <div className="form-group">
            <Helpbox
              form={{ template: this.state.tmpItem.blockContent }}
              itemElements={this.props.itemElements}
            />
            <div className="block-content-label-container">
              <label className="control-label">
                {strings.get(
                  "App.glossaries.glossaryForm.codeBlocks.blockContent"
                )}
              </label>
              <OverlayTrigger
                placement="left"
                overlay={tooltip}
                trigger="click"
                rootClose
              >
                <i className="ion-help-circled" />
                {/* <i className="ion-help-circled" onClick={this.showBlockContentInfoModal}/> */}
              </OverlayTrigger>
            </div>
            {blockContentError ? (
              <div className="alert alert-danger">{blockContentError}</div>
            ) : (
              ""
            )}
            {this.getTemplateErrors()}
            <Editor
              name="blockContent"
              content={this.state.tmpItem.blockContent}
              // handleChange={this.handleEditorChangeBlockContent}
              height={200}
              handleKeyUp={this.handleTemplateChange}
              handleChange={() => {}}
              onScroll={this.onScroll}
              isHasHelpbox
              fieldSuggest={this.state.fieldSuggest}
              codeSuggest={this.state.codeSuggest}
            />
          </div>
        </div>
        <div className="form-actions">
          <button
            className="btn btn-primary"
            onClick={this.handleVerifyCodeblock}
          >
            {strings.get("App.documents.documentForm.validate")}
          </button>
          <button className="btn btn-primary" onClick={this.handleSaveClick}>
            {strings.get("App.glossaries.glossaryForm.clauses.formModal.save")}
          </button>
          <button
            className="btn btn-default cancel"
            onClick={this.handleCancelClick}
          >
            {strings.get(
              "App.glossaries.glossaryForm.clauses.formModal.cancel"
            )}
          </button>
          {this.state.currentItemKey !== -1 && (
            <button
              className="btn btn-primary"
              onClick={(e) => this.handleSaveClick(e, true)}
            >
              {strings.get("App.documents.documentForm.orderFieldsModal.duplicate")}
            </button>
          )}
        </div>
      </FadeModal>
    );

    //1- Add Index of the Clauses
    let newItems = this.state.items;
    newItems &&
      newItems.map((item, index) => {
        item.index = index;
      });

    let labelClassName = this.props.currentItem
      ? "pointer control-label"
      : "control-label";
    let divStyle = this.props.currentItem ? {} : {};

    return (
      <div className="GlossaryFormCodeBlocks form-group">
        {/* {blockContentInfo} */}
        {deleteModal}
        {formModal}
        {codeOrderModal}
        <DropModal className="boron-modal" ref="documentListModal">
          <div className="modal-close-btn">
            <i
              className="ion-android-cancel clickable"
              onClick={this.hideDocumentListModal}
            />
          </div>
          {documentListModalContent}
        </DropModal>
        <div className="header">
          <span
            className="btn btn-primary small-add"
            onClick={this.handleAddClick}
          >
            <i className="ion-android-add" />
          </span>
        </div>
        <div style={divStyle} ref="hidden" className="table-responsive">
          <SortableTable
            items={this.state.items}
            prefix={this.props.prefix}
            onSortEnd={this.handleSortEnd}
            axis="xy"
            useDragHandle={true}
            lockToContainerEdges={true}
            helperClass="glossarySortableRow Clause"
          />
        </div>
      </div>
    );
  }
}

GlossaryFormCodeBlocks.propTypes = {
  items: PropTypes.array,
  // steps: PropTypes.object,
  glossaries: PropTypes.object.isRequired,
  currentGlossary: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  setCurrentModal: PropTypes.func.isRequired,
  currentModal: PropTypes.string.isRequired,
};

export default GlossaryFormCodeBlocks;
