import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import "../Page.scss";

import * as documentsActions from "../../store/documents/actions";
import * as documentsSelectors from "../../store/documents/selectors";
import * as categoriesActions from "../../store/categories/actions";
import * as categoriesSelectors from "../../store/categories/selectors";
import * as glossariesActions from "../../store/glossaries/actions";
import * as glossariesSelectors from "../../store/glossaries/selectors";
import * as stepsActions from "../../store/steps/actions";
import * as stepsSelectors from "../../store/steps/selectors";
import * as workflowsActions from "../../store/workflows/actions";
import * as workflowsSelectors from "../../store/workflows/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import Topbar from "../../components/Topbar";
import SubTopbar from "../../components/SubTopbar";
import DocumentForm from "../../components/document/DocumentForm";
import {
  getModal,
  getTemplateData,
} from "../../components/document/DocumentForm";
import _ from "lodash";

class DocumentEditPage extends Component {
  state = {
    fieldsLoaded: false,
    localStorageValue: null
  };
  storageListner = null;

  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.goInit();
    this.onEventListner();
  }

  componentWillUnmount() {
    this.props.unsetCurrentDocumentId();
    this.props.unsetFieldsOrder();
    this.props.clearExceptions();
    if(this.storageListner) window.removeEventListener("storage", this.storageListner)
  }

  onEventListner = () => {
    this.setState({
      localStorageValue: localStorage.getItem("localStroage-value")
    });
   this.storageListner = window.addEventListener("storage", () => this.localStorageCheck());
  }

  localStorageCheck = () => {
    const {localStorageValue} = this.state;
    const currentVal = localStorage.getItem("localStroage-value");
    if (localStorageValue != currentVal){
      this.goInit();
      this.props.fetchListElements();
      this.props.fetchAllGlossaries();
      this.setState({localStorageValue: currentVal});
    }
  }

  goInit = () => {
    this.props.fetchDocument(this.props.match.params.id);
    this.props.setCurrentDocumentId(this.props.match.params.id);
    if (!_.size(this.props.steps)) {
      this.props.fetchAllSteps();
    }
    if (!_.size(this.props.glossaries)) {
      this.props.fetchAllGlossaries();
    }
    if (!_.size(this.props.elements)) {
      this.props.fetchListElements();
    }
    this.props.fetchAllCategories();
    this.props.fetchWorkflowsWithoutPagination();
  }

  saveDocument(data) {
    this.props.updateDocument(this.props.match.params.id, data.form);
    if (data.file) {
      this.props.uploadDocumentLogo(this.props.match.params.id, data.file);
    }
  }

  updateItemOrder(data) {
    this.props.updateDocumentOrder(this.props.match.params.id, data);
  }

  updateStepOrder(data) {
    this.props.updateDocumentStepOrder(this.props.match.params.id, data);
  }

  validateDocument(data) {
    this.props.validateDoc(this.props.match.params.id, data);
  }

  showOrderFieldsModal() {
    let modal = getModal();
    if (
      !(
        this.props.exceptions.template &&
        this.props.exceptions.template.notDefined
      )
    )
      modal.show();
  }

  handleOrderFieldsClick() {
    this.setState({ fieldsLoaded: false });
    let templateData = getTemplateData();
    this.validateDocument(templateData);
    this.setState({ fieldsLoaded: true });
    _.delay(() => {
      this.showOrderFieldsModal();
    }, 250);
  }

  render() {
    return (
      <div className="DocumentEditPage">
        <Topbar templateGuide={true}>
          <div className="title">
            <Link to="/documents">
              {strings.get("App.documentPages.title")}
            </Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to={`/documents/${this.props.match.params.id}`}>
                {strings.get("App.documentPages.edit")}
              </Link>
            </span>
          </div>
          <div className="main-btns">
            <button
              className="btn btn-default btn-color-change"
              onClick={this.handleOrderFieldsClick}
            >
              {strings.get("App.documentPages.orderFields")}
            </button>
          </div>
        </Topbar>

        <div className="content">
          <DocumentForm
            exceptions={this.props.exceptions}
            categories={this.props.categories}
            glossaries={this.props.glossaries}
            currentItem={this.props.currentDocument}
            saveItem={this.saveDocument}
            fields={this.props.fields}
            fieldsOrder={this.props.fieldsOrder}
            stepsOrder={this.props.stepsOrder}
            selectors={this.props.selectors}
            clauses={this.props.clauses}
            steps={this.props.steps}
            stepsFromValidation={this.props.stepsFromValidation}
            updateItemOrder={this.updateItemOrder}
            updateStepOrder={this.updateStepOrder}
            updateGlossary={this.props.updateGlossary}
            createGlossary={this.props.createGlossary}
            formHeading={strings.get("App.documentPages.edit")}
            itemElements={this.props.elements}
            codeBlocks={this.props.codeBlocks}
            workflows={this.props.workflows}
            fetchCodesFromWorkFlows={this.props.fetchCodesFromWorkFlows}
            fetchCodesFromDocs={this.props.fetchCodesFromDocs}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    categories: categoriesSelectors.getItems(state),
    currentDocument: documentsSelectors.getCurrentItem(state),
    fields: documentsSelectors.getFields(state),
    fieldsOrder: documentsSelectors.getFieldsOrder(state),
    stepsOrder: documentsSelectors.getStepsOrder(state),
    selectors: documentsSelectors.getSelectors(state),
    clauses: documentsSelectors.getClauses(state),
    codeBlocks: documentsSelectors.getCodeBlocks(state),
    stepsFromValidation: documentsSelectors.getSteps(state),
    steps: stepsSelectors.getItems(state),
    glossaries: glossariesSelectors.getItems(state),
    elements: documentsSelectors.getElements(state),
    workflows: workflowsSelectors.getItemsByPage(
      state,
      workflowsSelectors.getPagination(state).currentPage
    ),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllCategories: (deleteCache) => {
      dispatch(categoriesActions.fetchAllItems(deleteCache));
    },
    fetchAllSteps: (deleteCache) => {
      dispatch(stepsActions.fetchAllItems(deleteCache));
    },
    fetchAllGlossaries: () => {
      dispatch(glossariesActions.fetchAllItems());
    },
    fetchDocument: (id) => {
      dispatch(documentsActions.fetchItem(id));
    },
    setCurrentDocumentId: (id) => {
      dispatch(documentsActions.setCurrentItemId(id));
    },
    unsetCurrentDocumentId: () => {
      dispatch(documentsActions.unsetCurrentItemId());
    },
    updateDocument: (id, data) => {
      dispatch(documentsActions.updateItem(id, data));
    },
    uploadDocumentLogo: (id, file) => {
      dispatch(documentsActions.uploadItemLogo(id, file));
    },
    createDocument: (data) => {
      dispatch(documentsActions.createItem(data));
    },
    validateDoc: (id, data) => {
      dispatch(documentsActions.validateItem(id, data));
    },
    fetchStep: (id) => {
      dispatch(stepsActions.fetchItem(id));
    },
    updateDocumentOrder: (id, data) => {
      dispatch(documentsActions.updateItemOrder(id, data));
    },
    updateDocumentStepOrder: (id, data) => {
      dispatch(documentsActions.updateStepOrder(id, data));
    },
    updateGlossary: (id, data) => {
      dispatch(glossariesActions.updateItem(id, data));
    },
    createGlossary: (data) => {
      dispatch(glossariesActions.createItem(data));
    },
    unsetFieldsOrder: () => {
      dispatch(documentsActions.unsetFieldsOrder());
    },
    fetchListElements: () => {
      dispatch(documentsActions.fetchListElements());
    },
    fetchWorkflowsWithoutPagination: (deleteCache) => {
      dispatch(workflowsActions.fetchItemsWithoutPagination(deleteCache));
    },
    fetchCodesFromWorkFlows: (data, success) => {
      dispatch(workflowsActions.fetchCodesFromWorkFlows(data, success));
    },
    fetchCodesFromDocs: (data, success) => {
      dispatch(documentsActions.fetchCodesFromDocs(data, success));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentEditPage);
