import React, { Component } from 'react';
import autoBind from 'react-autobind';
import strings from '../../services/strings';
import language from '../../services/language';
import PropTypes from "prop-types";
import _ from 'lodash';

import './GroupListSorter.scss';

class GroupListSorter extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

 

    getSorter() {
        if (this.props.sorter) {
            return this.props.sorter.column;
        }
        return '';
    }

    getPageLimit = () => {
        if (this.props.sorter) {
            return this.props.sorter.pageLimit;
        }

        return '';
    }


    handleSortChange = (e) => {
        let sorter = {
            column: e.target.value,
        };
       
         if (sorter.column === 'name'){
            sorter.descending= false;
        }
        
        this.props.setSorter(sorter);
        this.props.fetchItems(true);
    }

    handleChange(e) {
        let sorter = {
            pageLimit: e.target.value,
        }
        this.props.setSorter(sorter);
        this.props.fetchItems(true);
    }

    handleOrder = () => {
        let sorter = {
          descending: !this.props.sorter.descending
        }
        this.props.setSorter(sorter);
        this.props.fetchItems(true);
    }

    render() {
        return (
            <div className="Partnersize">
                 <label>{strings.get("App.companies.sorter.title")}</label>
                <select
                    className="form-control"
                    name="sorter"
                    value={this.getSorter()}
                    onChange={this.handleSortChange}
                >
                    <option value="first_name">
                        {strings.get("App.companies.sorter.name")}
                    </option>

                    <option value="created_at">
                        {strings.get("App.companies.sorter.lastCreate")}
                    </option>
                    <option value="updated_at">
                        {strings.get("App.companies.sorter.lastUpdate")}
                    </option>
                </select>

                <span className="ml-5" style={{color:'#000000'}} onClick={this.handleOrder}>
                    {this.props.sorter.descending ?
                        (<i title="Set to Ascending Direction" class="fa fa-arrow-down"></i>)
                         : 
                        (<i title="Set to Descending Direction" class="fa fa-arrow-up"></i>)
                    }
                </span>

                <select 
                    className="form-control" 
                    name="sorter" 
                    value={ this.getPageLimit()} 
                    onChange={ this.handleChange }
                >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
            </div>
        );
    }

}

GroupListSorter.propTypes = {
    sorter: PropTypes.object,
    setSorter: PropTypes.func.isRequired,
    fetchItems: PropTypes.func.isRequired,
}

export default GroupListSorter;
