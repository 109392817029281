import React, { Component } from "react";
import autoBind from "react-autobind";
import strings from "../../services/strings";
import language from "../../services/language";
import _ from "lodash";
import PropTypes from "prop-types";

import "./DocumentSorter.scss";

class DocumentSorter extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      column : "name",
      pageLimit: 10,
      descending: props.sorter.descending
    };
  }

  getSorter() {
    if (this.props.sorter) {
      return this.props.sorter.column;
    }

    return "";
  }

  handleChange(e) {
    let sorter = {
      column: e.target.value,
      descending: this.state.descending,
      pageLimit: this.getSorterPageSize()
    };

    if (sorter.column === "name") {
      sorter.descending = false;
    }
    this.setState({
      column : sorter.column,
      descending: sorter.descending,
      pageLimit : sorter.pageLimit
    });
    this.props.setSorter(sorter);
    this.props.fetchItems(true);
  }

  getSorterPageSize() {
    if (this.props.sorter) {
      return this.props.sorter.pageLimit;
    }

    return "";
  }

  handleChangePageSize(e) {
    let sorter = {
      column: this.state.column,
      descending: this.state.descending,
      pageLimit: e.target.value
    };
    this.setState({
      pageLimit : sorter.pageLimit
    });
    this.props.setSorter(sorter);
    this.props.fetchItems(true);
  }

  handleOrder = () => {
    let sorter = {
        descending: !this.props.sorter.descending
    }
    this.props.setSorter(sorter);
    this.props.fetchItems(true);
}

  render() {
    return (
      <div className="DocumentSorter">
        <label>{strings.get("App.documents.sorter.title")}</label>
        <select
          className="form-control"
          name="sorter"
          value={this.getSorter()}
          onChange={this.handleChange}
        >
          <option value="name">
            {strings.get("App.documents.sorter.name")}
          </option>
          <option value="price">
            {strings.get("App.documents.sorter.price")}
          </option>
          <option value="downloads">
            {strings.get("App.documents.sorter.downloads")}
          </option>
          <option value="created_at">
            {strings.get("App.documents.sorter.newest")}
          </option>
          <option value="validDoc">
            {strings.get("App.documents.sorter.brokenDocs")}
          </option>
          <option value="published">
            {strings.get("App.documents.sorter.published")}
          </option>
          <option value="depublished">
            {strings.get("App.documents.sorter.depublished")}
          </option>
          <option value="paid">
            {strings.get("App.documents.sorter.paid")}
          </option>
          <option value="free">
            {strings.get("App.documents.sorter.free")}
          </option>
          <option value="tested">
            {strings.get("App.documents.sorter.tested")}
          </option>
          <option value="non_tested">
            {strings.get("App.documents.sorter.nonTested")}
          </option>
        </select>
        <span className="ml-5" style={{color:'#000000'}} onClick={this.handleOrder}>
            {this.props.sorter.descending ?
              (<i title="Set to Ascending Direction" class="fa fa-arrow-down"></i>)
               : 
              (<i title="Set to Descending Direction" class="fa fa-arrow-up"></i>)
            }
          </span>
        <select
          className="form-control"
          name="sorter"
          value={this.getSorterPageSize()}
          onChange={this.handleChangePageSize}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
    );
  }
}

DocumentSorter.propTypes = {
  sorter: PropTypes.object,
  setSorter: PropTypes.func.isRequired,
  fetchItems: PropTypes.func.isRequired
};

export default DocumentSorter;
